<template>
    <section id="cta" class="cta" :class="{'cta2' : is2}">
        <div class="container">
            <div class="row">
                <div class="text-section col-12 col-lg-7">
                    <h1 class="question" :class="{'q-mb' : answer3}">{{ question }}</h1>
                    <p :class="{'p-mt' : answer3}">{{ answer1 }}</p>
                    <p v-if="answer2">{{ answer2 }}</p>
                    <p v-if="answer3">{{ answer3 }}</p>
                </div>
                <div class="img-section col-12 col-lg-5" :class="{'img-section-2' : is2}">
                    <h1 class="title" v-if="title">{{ title }}</h1>
                    <ImageHolder class="cta-img" :imageName="img" altText="" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ImageHolder from './comps/ImageHolder.vue';
export default {
    name: 'CTASection',
    components: {
    ImageHolder,
},
    props: {
        question: {
            type: String,
            required: true
        },
        answer1: {
            type: String,
            required: true
        },
        answer2: {
            type: String,
            required: false
        },
        answer3: {
            type: String,
            required: false
        },
        title: {
            type: String,
            required: false
        },
        img: {
            type: String,
            required: true
        },
        is2: {
            type: Boolean,
            required: false
        }
    },
}
</script>

<style lang="scss" scoped>
.q-mb {
    margin-bottom: 0 !important;
}
.p-mt {
    margin-top: 0 !important;
}
.cta2 {
    padding-top: 0 !important;
}
.img-section-2 {
    > .cta-img {
        max-width: 100%;
        @media (min-width: $breakpoint-mobile) {
            max-width: 55% !important;
        }
        @media (min-width: $breakpoint-tablet) {
            max-width: 75% !important;
        }
    }
}
.cta {
    padding: 2%;
    @media (min-width: $breakpoint-mobile) {
        padding: 12% 6% 2%;;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 6% 7% 0%;
    }
    > .container {
        max-width: 1325px;
        padding: 0;
        > .row {
        > .text-section {
            @media (min-width: $breakpoint-mobile) {
                text-align: left;
            }
            > .question {
                font-family: $font-family-4;
                font-size: 30px;
                color: #298B99;
                font-weight: 700;
                margin-bottom: 0;
            }
            > p {
                font-size: 18px;
                color: #646464;
                padding-top: 0;
                line-height: 30px;
                font-family: $font-fam;
                @media (min-width: $breakpoint-mobile) {
                    padding-top: 0.5rem;
                }
            }
        }
        > .img-section {
            > .title {
                font-family: $font-family-4;
                font-size: 30px;
                color: #FFB500;
                font-weight: 700;
                padding-top: 70px;
                margin-top: 0;
                @media (min-width: $breakpoint-mobile) {
                    padding-left: 0;
                    padding-right: 0;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding-left: 6%;
                    padding-right: 6%;
                }
            }
            > .cta-img {
                max-width: 100%;
                @media (min-width: $breakpoint-tablet) {
                    max-width: 100%;
                }
            }
        }
    }
    }
}
</style>