<template>
    <section id="steps">
        <div class="text-section container">
            <h1 v-if="annuity_type !== 'Growth'">Here’s How Annuity Gator Can Help You Achieve Your Retirement Income Goals</h1>
            <h1 v-else>Here’s How Annuity Gator Can Help You Securely Grow Your Money In Retirement</h1>
        </div>
        <div class="steps-container container">
            <div class="row">
                <div class="card col-6 col-lg-3">
                    <ImageHolder imageName="review" altText="Review Image" />
                    <p>We will gather some basic information from you to understand your retirement and financial goals over the phone.</p>
                </div>
                <div class="card col-6 col-lg-3">
                    <ImageHolder imageName="research" altText="Research Image" />
                    <p v-if="annuity_type === 'Income'">Our annuity research team will calculate exactly how much guaranteed lifetime income will the top insurance companies pay you.</p>
                    <p v-else>Our annuity research team will scour the internet to find the best protected growth annuities offered by top-rated insurance companies.</p>
                </div>
                <div class="card col-6 col-lg-3">
                    <ImageHolder imageName="recommend_img" altText="Recommend Image" />
                    <p v-if="annuity_type === 'Income'">We will identify the top three income annuities that you can compare side-by-side.</p>
                    <p v-else>We will identify the top three protected growth annuities that you can compare side-by-side.</p>
                </div>
                <div class="card col-6 col-lg-3">
                    <ImageHolder imageName="retirement" altText="Retirement Image" />
                    <p v-if="annuity_type === 'Income'">We will guide you through the process of purchasing an annuity. Gain peace of mind knowing that you will have a predictable paycheck throughout your retirement.</p>
                    <p v-else>We will guide you through the process of purchasing an annuity. Gain peace of mind knowing that your assets are protected from market losses while being able to earn an annual return on your money at the same time.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ImageHolder from './comps/ImageHolder.vue';
export default {
    name: 'StepsSection',
    components: {
        ImageHolder,
    },
    computed: {
        annuity_type() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now' || this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'Income'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Growth'
            }
            return type;
        },
    }
}
</script>

<style lang="scss" scoped>
#steps {
    background-color: #F6F6F6;
    padding-top: 15px;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: $breakpoint-mobile) {
        padding-top: 60px;
        padding-bottom: 60px;
    }
    > .text-section {
        > h1 {
            text-align: center;
            color: #298B99;
            max-width: 935px;
            font-size: 24px;
            font-family: $font-family-4;
            @media (min-width: $breakpoint-mobile) {
                font-size: 30px;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-bottom: 1%;
            }
        }
    }
    > .steps-container {
        max-width: 1325px;
        > .row {
            > .card {
                @media (min-width: $breakpoint-mobile) {
                    padding: 0 5%;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding: 0 1.5%;
                }
                > img {
                    width: 100%;
                    @media (min-width: $breakpoint-mobile) {
                        width: 85%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        width: 93%;
                    }
                }
                > p {
                    font-size: 16px;
                    line-height: 1.5;
                    // color: #4a4a4a;
                    padding: 0 8%;
                    color: var(--black);
                    font-family: $font-fam;
                    text-align: left;
                    @media (min-width: $breakpoint-mobile) {
                        color: var(--black);
                        font-weight: 500;
                        font-size: 18px;
                        text-align: left;
                        padding: 0 5%;
                        margin-left: -5%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        
                    }
                }
            }
        }
    }
}
</style>