// store.js
import createPersistedState from "vuex-persistedstate";
export default {
    state: {
      answers: {}, // Store answers here
      rep_name: "",
      isLoading: false,
      formData: {},
      id: ''
    },
    
    mutations: {
      setAnswer(state, { questionKey, answer }) {
        // Set the answer for a specific question
        state.answers[questionKey] = answer;
      },
      setRepName(state, rep_name) {
        state.rep_name = rep_name;
      },
      SET_LOADING(state, isLoading) {
        state.isLoading = isLoading;
      },
      SET_FORM_DATA(state, formData) {
        state.formData = formData; // Update the form data in the state
      },
      SET_ID(state, id) {
        state.id = id
      }
    },
    actions: {
      saveAnswer({ commit }, { questionKey, answer }) {
        // Call this action to save an answer
        commit('setAnswer', { questionKey, answer });
      },
      saveRepName({ commit }, rep_name) {
        commit('setRepName', rep_name);
      },
      setLoading({ commit }, isLoading) {
        commit('SET_LOADING', isLoading);
      },
      setFormData({commit}, formData) {
        commit('SET_FORM_DATA',formData)
      },
      setId({commit}, id){
        commit('SET_ID', id)
      }
    },
    
    plugins: [createPersistedState()],
  };
  