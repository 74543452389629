<template>
  <div class="svg-icon">
    <circle v-if="iconPaths[name] === 'circleChevron'" class="arrow-slider-circle" cx="12" cy="12" r="11.5" fill="none" stroke="#8C8C8C"></circle>
    <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" :viewBox="iconPaths[name].view">
      <path :d="iconPaths[name].path" :style="{fill: fillColor}" />
    </svg>
  </div>
</template>

<script>
import iconPaths from "../icons.js"; // Import the icon paths

export default {
  props: {
    name: String,
    width: {
      type: [String, Number],
      default: "24",
    },
    height: {
      type: [String, Number],
      default: "24",
    },
    fillColor: {
      type: String,
      default: "currentColor", // Default fill color
    },
  },
  data() {
    return {
      iconPaths, // Provide access to the imported icon paths
    };
  },
};
</script>

<style scoped>
.svg-icon {
  display: inline-block;
  /* You can add more styling for the container here */
}
</style>
