<template>
    <section id="video">
        <div class="video-container container">
            <div class="row">
                <div class="video-section col-12 col-md-6" style="padding-right:3%;">
                    <WistiaVideo :videoId="yourVideoIdHere" />
                </div>
                <div class="text-section col-12 col-md-6">
                    <h1 class="title">Why Do Clients Throughout The Country Work With Annuity Gator And Why Should You?</h1>
                    <p class="text">We reinvented how annuities are sold, and we put the power in your hands. Our licensed annuity professionals are independent and not captive or beholden to any one particular insurance company.</p>
                    <p class="text">We hate high-pressure sales tactics as much as you do. We’ll provide you with honest annuity guidance and personalized annuity recommendations right over the phone.</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import WistiaVideo from './comps/WistiaVideo.vue';
export default {
    name: 'VideoSection',
    components: {
        WistiaVideo
    },
    data() {
        return {
            yourVideoIdHere: '1x4s6tbo38',
        }
    }
}
</script>

<style lang="scss" scoped>
#video {
    padding: 18% 0 0;
    @media (min-width: $breakpoint-mobile) {
        padding: 20% 0 7%;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 20% 0 4%;
    }
    .video-container {
        text-align: center;
        max-width: 1325px;
        @media (min-width: $breakpoint-mobile) {
            text-align: left;
        }
        > .row {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            @media (min-width: $breakpoint-mobile) {
                align-items: flex-start;
            }
            @media (min-width: $breakpoint-tablet) {
                align-items: flex-start;
            }
            > .video-section {
                margin-top: 200px;
                @media (min-width: $breakpoint-mobile) {
                    margin-top: 0;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding-right: 3% !important;
                }
            }
            > .text-section {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 30px;
                @media (min-width: $breakpoint-mobile) {
                    margin-top: 0;
                    padding-right: 7%;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding-right: 0;
                }
                > .title {
                        font-family: $font-family-4;
                        font-size: 24px;
                        color: #fff;
                        font-weight: 700;
                        margin-top: 0;
                        @media (min-width: $breakpoint-mobile) {
                            font-size: 30px;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            margin-bottom: 2%;
                            margin-top: 4%;
                        }
                }
                > .text {
                    color: black;
                    font-size: 20px;
                    line-height: 28px;
                    margin-bottom: 2%;
                    font-family: $font-fam;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

</style>