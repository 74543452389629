// src/iconPaths.js

const iconPaths = {
    check: {
        path: "M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z",
        view: "0 0 448 512"
    },
    check2: {
        path: "M29.333 10.267c0 0.4-0.133 0.8-0.533 1.2l-14.8 14.8c-0.267 0.267-0.667 0.4-1.067 0.4s-0.933-0.133-1.2-0.533l-2.4-2.267-6.267-6.267c-0.267-0.267-0.4-0.667-0.4-1.2s0.133-0.8 0.533-1.2l2.4-2.4c0.267-0.133 0.667-0.4 1.067-0.4s0.8 0.133 1.2 0.533l5.067 5.067 11.2-11.333c0.267-0.267 0.667-0.533 1.2-0.533 0.4 0 0.8 0.133 1.2 0.533l2.4 2.4c0.267 0.267 0.4 0.667 0.4 1.2z",
        view: "0 0 32 32"
    },
    money: {
        path: "M512,96a32,32,0,0,1,32,32v32a64.06,64.06,0,0,1-64-64ZM128,96H448a96,96,0,0,0,96,96V320a96,96,0,0,0-96,96H128a96,96,0,0,0-96-96V192A96,96,0,0,0,128,96ZM32,352a64.06,64.06,0,0,1,64,64H64a32,32,0,0,1-32-32Zm448,64a64.06,64.06,0,0,1,64-64v32a32,32,0,0,1-32,32ZM96,96a64.06,64.06,0,0,1-64,64V128A32,32,0,0,1,64,96ZM64,64A64.06,64.06,0,0,0,0,128V384a64.06,64.06,0,0,0,64,64H512a64.06,64.06,0,0,0,64-64V128a64.06,64.06,0,0,0-64-64ZM192,256a96,96,0,1,1,96,96A96,96,0,0,1,192,256Zm224,0A128,128,0,1,0,288,384,128,128,0,0,0,416,256Z", 
        view: "0 0 576 384"
    },
    woman: {
        path: "M208 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zm21.7 185.7l37.4 66.1c6.5 11.5 21.2 15.6 32.7 9.1s15.6-21.2 9.1-32.7l-51.8-91.5c-19.8-35-56.9-56.6-97.1-56.6s-77.3 21.6-97.1 56.6L11.1 276.2c-6.5 11.5-2.5 26.2 9.1 32.7s26.2 2.5 32.7-9.1l37.4-66.1L53.7 363.7C50.8 373.9 58.5 384 69.1 384H96V488c0 13.3 10.7 24 24 24s24-10.7 24-24V384h32V488c0 13.3 10.7 24 24 24s24-10.7 24-24V384h26.9c10.6 0 18.3-10.1 15.4-20.3L229.7 233.7zM160 176c2.2 0 4 1.4 4.6 3.5l44 156.5H111.4l44-156.5c.6-2.1 2.5-3.5 4.6-3.5z",
        view: "0 0 320 512"
    },
    man: {
        path: "M160,96a48,48,0,1,0-48-48A48,48,0,0,0,160,96Zm-13.7,80h27.5a17.7,17.7,0,0,1,2.3.1V304H144V176.1C144.7,176.1,145.5,176,146.3,176ZM144,488V352h32V488a24,24,0,0,0,48,0V223.6l43.1,76.2a24,24,0,0,0,41.8-23.6L250.3,172.7A88,88,0,0,0,173.7,128H146.3a88,88,0,0,0-76.6,44.7L11.1,276.2a24,24,0,1,0,41.8,23.6L96,223.6V488a24,24,0,0,0,48,0Z",
        view: "0 0 304 512"
    },
    rings: {
        path: "M119.9 105.1C50.2 128.6 0 194.4 0 272c0 97.2 78.8 176 176 176c7.8 0 15.5-.5 23-1.5c32.3 39.9 81.6 65.5 137 65.5c97.2 0 176-78.8 176-176c0-91.9-70.4-167.3-160.3-175.3c10.4 16.5 18.6 34.5 24.2 53.6C427.1 231.1 464 279.2 464 336c0 70.7-57.3 128-128 128c-32.8 0-62.7-12.3-85.3-32.6l0 0c-12.3-11-22.5-24.4-29.8-39.5l0 0C212.6 375 208 356.1 208 336c0-54.9 34.6-101.8 83.2-119.9C299.4 233 304 251.9 304 272c0 43.5-21.7 81.9-54.8 105c7 14.8 17.7 27.5 30.8 37c43.7-32 72-83.7 72-142c0-77.6-50.2-143.4-119.9-166.9l22.5-50.6c1.9-4.4 1.8-9.4-.3-13.7l-16-32C235.6 3.4 230.1 0 224 0H128c-6.1 0-11.6 3.4-14.3 8.8l-16 32c-2.1 4.3-2.3 9.3-.3 13.7l22.5 50.6zM147.8 48h56.4l.4 .8L187.2 88H164.8L147.4 48.8l.4-.8zM160 336c0 22.6 4.2 44.1 12 63.9C103.1 397.8 48 341.3 48 272c0-70.7 57.3-128 128-128c32.8 0 62.7 12.3 85.3 32.6C201.5 204.7 160 265.5 160 336z",
        view: "0 0 512 512"
    },
    plant: {
        path: "M480 32c0 95.3-69.4 174.4-160.5 189.4c2.7 10.2 4.7 20.7 6.1 31.4C431.4 234.9 512 142.9 512 32c0-17.7-14.3-32-32-32H448C367 0 296 43 256.6 107.5c7.8 8.3 15.1 17.2 21.8 26.4C310.7 73.3 374.5 32 448 32h32zM64 96c106 0 192 86 192 192H224C118 288 32 202 32 96H64zM32 64C14.3 64 0 78.3 0 96C0 219.7 100.3 320 224 320h32V496c0 8.8 7.2 16 16 16s16-7.2 16-16V320 304 288C288 164.3 187.7 64 64 64H32z",
        view: "0 0 512 512"
    },
    vault: {
        path: "M64 32C46.3 32 32 46.3 32 64V416c0 17.7 14.3 32 32 32H512c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32H64zM0 64C0 28.7 28.7 0 64 0H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64v16c0 8.8-7.2 16-16 16s-16-7.2-16-16V480H96v16c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-16c-35.3 0-64-28.7-64-64V64zM352 240A128 128 0 1 0 96 240a128 128 0 1 0 256 0zM64 240a160 160 0 1 1 320 0A160 160 0 1 1 64 240zm160 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm0-96a64 64 0 1 1 0 128 64 64 0 1 1 0-128zm256 0a16 16 0 1 0 -32 0 16 16 0 1 0 32 0zm32 0c0 20.9-13.4 38.7-32 45.3V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V221.3c-18.6-6.6-32-24.4-32-45.3c0-26.5 21.5-48 48-48s48 21.5 48 48z",
        view: "0 0 576 512"
    },
    x: {
        path: "M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z",
        view: "0 0 384 512"
    },
    lock: {
        path: "M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z",
        view: "0 0 24 24"
    },
    star: {
        path: "M12,15.39L8.24,17.66L9.23,13.38L5.91,10.5L10.29,10.13L12,6.09L13.71,10.13L18.09,10.5L14.77,13.38L15.76,17.66M22,9.24L14.81,8.63L12,2L9.19,8.63L2,9.24L7.45,13.97L5.82,21L12,17.27L18.18,21L16.54,13.97L22,9.24Z",
        view: "0 0 24 24"
    },
    moneySign: {
        path: "M11.5 17.1c-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79z",
        view: "0 0 24 24"
    },
    chat: {
        path: "M532 386.2c27.5-27.1 44-61.1 44-98.2 0-80-76.5-146.1-176.2-157.9C368.3 72.5 294.3 32 208 32 93.1 32 0 103.6 0 192c0 37 16.5 71 44 98.2-15.3 30.7-37.3 54.5-37.7 54.9-6.3 6.7-8.1 16.5-4.4 25 3.6 8.5 12 14 21.2 14 53.5 0 96.7-20.2 125.2-38.8 9.2 2.1 18.7 3.7 28.4 4.9C208.1 407.6 281.8 448 368 448c20.8 0 40.8-2.4 59.8-6.8C456.3 459.7 499.4 480 553 480c9.2 0 17.5-5.5 21.2-14 3.6-8.5 1.9-18.3-4.4-25-.4-.3-22.5-24.1-37.8-54.8zm-392.8-92.3L122.1 305c-14.1 9.1-28.5 16.3-43.1 21.4 2.7-4.7 5.4-9.7 8-14.8l15.5-31.1L77.7 256C64.2 242.6 48 220.7 48 192c0-60.7 73.3-112 160-112s160 51.3 160 112-73.3 112-160 112c-16.5 0-33-1.9-49-5.6l-19.8-4.5zM498.3 352l-24.7 24.4 15.5 31.1c2.6 5.1 5.3 10.1 8 14.8-14.6-5.1-29-12.3-43.1-21.4l-17.1-11.1-19.9 4.6c-16 3.7-32.5 5.6-49 5.6-54 0-102.2-20.1-131.3-49.7C338 339.5 416 272.9 416 192c0-3.4-.4-6.7-.7-10C479.7 196.5 528 238.8 528 288c0 28.7-16.2 50.6-29.7 64z",
        view: "0 0 576 512"
    },
    search: {
        path: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
        view: "0 0 24 24"
    },
    chart: {
        path: "M9 17H7V10H9M13 17H11V7H13M17 17H15V13H17M19.5 19.1H4.5V5H19.5M19.5 3H4.5C3.4 3 2.5 3.9 2.5 5V19C2.5 20.1 3.4 21 4.5 21H19.5C20.6 21 21.5 20.1 21.5 19V5C21.5 3.9 20.6 3 19.5 3Z",
        view: "0 0 24 24"
    },
    medal: {
        path: "M14.94 19.5L12 17.77L9.06 19.5L9.84 16.16L7.25 13.92L10.66 13.63L12 10.5L13.34 13.63L16.75 13.92L14.16 16.16M20 2H4V4L8.86 7.64A8 8 0 1 0 15.14 7.64L20 4M18 15A6 6 0 1 1 10.82 9.12A5.86 5.86 0 0 1 13.18 9.12A6 6 0 0 1 18 15M12.63 7H11.37L7.37 4H16.71Z",
        view: "0 0 24 24"
    },
    plus: {
        path: "M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z",
        view: "0 0 24 24"
    },
    circleChevron: {
        path: "M10.5088835 12l3.3080582-3.02451041c.2440777-.22315674.2440777-.5849653 0-.80812204-.2440776-.22315673-.6398058-.22315673-.8838834 0L9.18305826 11.595939c-.24407768.2231567-.24407768.5849653 0 .808122l3.75000004 3.4285714c.2440776.2231568.6398058.2231568.8838834 0 .2440777-.2231567.2440777-.5849653 0-.808122L10.5088835 12z",
        view: "0 0 24 24"
    },
    chevronDown: {
        path: "M267.3 395.3c-6.2 6.2-16.4 6.2-22.6 0l-192-192c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L256 361.4 436.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-192 192z",
        view: "0 0 512 512"
    },
    phone: {
        path: "M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z",
        view: "0 0 512 512"
    },
    calendar: {
        path: "M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192h80v56H48V192zm0 104h80v64H48V296zm128 0h96v64H176V296zm144 0h80v64H320V296zm80-48H320V192h80v56zm0 160v40c0 8.8-7.2 16-16 16H320V408h80zm-128 0v56H176V408h96zm-144 0v56H64c-8.8 0-16-7.2-16-16V408h80zM272 248H176V192h96v56z",
        view: "0 0 448 512"
    }
  };
  
  export default iconPaths;
  