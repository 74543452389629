import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './assets/css/bootstrap-grid.min.css';
import Vuex from 'vuex';
import storeData from './store';
import mixpanel from 'mixpanel-browser';

mixpanel.init('16af4f14075e6a4ee06082a56f9ddad0');
const store = new Vuex.Store(storeData);

createApp(App).use(router).use(store).mount('#app')
