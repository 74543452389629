<template>
    <div v-if="!isQ7" class="quiz-question backInRight" :class="{'backOutLeft' : nextQ}">
      <div class="container" :class="{'q5-container' : isQ5, 'container-pt' : (isQ1 || isQ3 || isQ6)}">
        <div v-if="firstq" class="firstq1">
          <p>An exclusive opportunity for investors with $100,000 or more in their retirement portfolio</p>
        </div>
        <div class="title">
          <h1 v-if="titleText" class="green-title">{{ titleText }}</h1>
        </div>
        <div class="q">
          <p v-if="firstq" class="firstq2">Find out if you qualify for a personalized annuity evaluation in under 30 seconds...</p>
        </div>
        <div v-if="isQ5" class="when-it-comes">
          <p>When it comes to <span class="green-var">{{ gender }},</span> Who Are <span class="green-var">{{ marital_status }},</span><span class="green-var">{{ age }}</span><br><span class="green-var">{{ retired }}</span></p>
          <p class="subtext">They Tend To Fall Into 1 of 3 Categories.</p>
        </div>
        <div class="question" :class="{'qq5' : isQ5}">
          <h1 class="main-q" :class="{'q5': isQ5}">{{ question }}</h1>
        </div>
        <div class="clarify" :class="{'q6' : isQ6}">
          <p v-if="clarification" class="clarification">{{ clarification }}</p>
        </div>
        <!-- Option Subcomponent -->
        <div class="contain" :class="{'q5-cont' : isQ5, 'q3-cont' : isQ3}">
          <div class="option-container" :style="optionContainerStyle">
            <OptionCard :options="currentOptions" @answer="handleAnswer" />
          </div>
        </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import OptionCard from './OptionCard.vue'; // Import the OptionCard component
  
  export default {
    props: {
      questionData: Object, // Pass the entire question object as a prop
    },
    computed: {
      firstq() {
        return this.questionData.title;
      } , 
      titleText() {
        // Compute the title text based on the questionData
        return this.questionData.title; // Example: Access the title from the question data
      },
      question() {
        // Compute the question text based on the questionData
        return this.questionData.question; // Example: Access the question from the question data
      },
      clarification() {
        // Compute the clarification text based on the questionData
        return this.questionData.clarification; // Example: Access the clarification from the question data
      },
      currentOptions() {
        // Compute the options based on the questionData
        return this.questionData.options; // Example: Access the options from the question data
      },
      optionContainerStyle() {
        // Determine whether flex-wrap should be applied based on the question
        return {
          'flex-wrap': (this.questionData.question === 'What Is Your Age Range?' || this.questionData.question === 'Which of the Following Categories Best Describes Why You Would Consider An Annuity The Most (if you had to pick one)?') ? 'wrap' : 'nowrap',
        };
      },
      containStyle() {
        // Determine whether flex-wrap should be applied based on the question
        return {
          'width': (this.questionData.question === 'What Is Your Age Range?' || this.questionData.question === 'Which of the Following Categories Best Describes Why You Would Consider An Annuity The Most (if you had to pick one)?') ? '80%' : '50%',
        };
      },
      isQ1() {
        return this.questionData.question === 'Are You a Man or Woman?' ? true : false;
      },
      isQ5() {
        return this.questionData.question === 'Which of the Following Categories Best Describes Why You Would Consider An Annuity The Most (if you had to pick one)?' ? true : false;
      },
      isQ6() {
        return this.questionData.question === 'Do you have at least $100,000 or more in your retirement portfolio?' ? true : false;
      },
      isQ3() {
        return this.questionData.question === 'What Is Your Age Range?' ? true : false;
      },
      isQ7() {
        return this.questionData.question === '' ? true : false;
      },
      gender() {
        let gender_text = ''
        if(this.$store.state.answers.Q1.text === 'Woman') {
          gender_text = 'Women'
        } else if(this.$store.state.answers.Q1.text === 'Man') {
          gender_text = 'Men'
        }
        return gender_text
      },
      marital_status() {
        return this.$store.state.answers.Q2.text
      },
      age() {
        let age_text = ''
            if(this.$store.state.answers.Q3.number === '50') {
                age_text = `Under The Age of ${this.$store.state.answers.Q3.number},`
            } else if(this.$store.state.answers.Q3.number === '70') {
                age_text = `Over The Age of ${this.$store.state.answers.Q3.number},`
            } else {
                age_text = `Between The Ages of ${this.$store.state.answers.Q3.number},`
            }
        return age_text
      },
      retired() {
        let retired_text = ''
            if(this.$store.state.answers.Q4.text === 'Yes') {
                retired_text = 'Who Are Retired.'
            } else if(this.$store.state.answers.Q4.text === 'No') {
                retired_text = 'Who Are Not Retired.'
            }
        return retired_text
      }

    },
    components: {
      OptionCard,
    },
    data() {
      return {
        selectedAnswer: null, // Initialize selectedAnswer to null
        nextQ: false,
        windowWidth: screen.width,
      }
    },
    methods: {
      handleAnswer(answer) {
        this.nextQ = true;
        setTimeout(() => {
          this.nextQ  = false;
          this.selectedAnswer = answer; // Update selectedAnswer with the answer from OptionCard
         if(this.selectedAnswer.id === 'no-100k') {
            this.$router.push('/not-qualified');
          }
          this.$emit('answer', answer); // Emit the answer to the parent component (QuizPage)
          
        }, 0); 
      },
      
    }
  };
  </script>

  <style lang="scss" scoped>
  .container-pt {
    padding-top: 0;
    @media (min-width: $breakpoint-mobile) {
      padding-top: 40px !important;
    }
  }
  .q5 {
    color: #42a635;
    font-weight: 700;
    font-size: 21px !important;
    @media (min-width: $breakpoint-mobile) {
      font-size: 21px !important;
      margin-bottom: 10px;
    }
    @media (min-width: $breakpoint-tablet) {
      color: var(--black);
      font-size: 28px !important;
    }
  }
  .qq5 {
    padding: 0;
    margin-top: 0;
    @media (min-width: $breakpoint-mobile) {
      padding: 0 3% !important;
    }
    @media (min-width: $breakpoint-tablet) {
      padding: 0 13% !important;
      margin-top: 1.5% !important;
    }
  }
  .q5-cont {
    width: 100% !important;
    @media (min-width: $breakpoint-mobile) {
      width: 72% !important;
    }
    @media (min-width: $breakpoint-tablet) {
      width: 100% !important;
    }
  }
  .q3-cont {
    width: 100% !important;
    @media (min-width: $breakpoint-mobile) {
      width: 80% !important;
    }
    @media (min-width: $breakpoint-tablet) {
      width: 80% !important;
    }
  }
  .q5-container {
    height: 860px !important;
  }
  .q6 {
    padding: 0 5% !important;
  }
  .quiz-question {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto 3%;
    color: var(--black);
    padding: 0;
    font-family: $font-fam;
    @media (min-width: $breakpoint-mobile) {
      padding: 0 3%;
    }
    @media (min-width: $breakpoint-tablet) {
      padding: 0 3%;
      margin: 3% auto;
      margin-top: 0.5%;
    }
    .container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      max-width: 1200px;
      padding: 0 7px 7px;
      height: 860px;
      padding-top: 0;
      @media (min-width: $breakpoint-mobile) {
        padding: 52px 18px 20px;
        height: 722px;
      }
      @media (min-width: $breakpoint-tablet) {
        padding-top: 52px;
      }
        > .firstq1 {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 10px;
        line-height: 1.7rem;
        > p {
          margin: 0;
          padding: 0;
          @media (min-width: $breakpoint-mobile) {
            margin: 10px 0 0;
          }
        }
      }
      > .q {
        padding: 0 4%;
        @media (min-width: $breakpoint-mobile) {
          padding: 0;
        }
        > .firstq2 {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
          line-height: 1.7rem;
        }
      }
      > .title {
        padding: 0 5%;
        margin-top: 5px;
        @media (min-width: $breakpoint-mobile) {
          padding: 0;
        }
        @media (min-width: $breakpoint-tablet) {
          padding: 0 10%;
        }
        > .green-title {
          font-size: 20px;
          font-weight: 700;
          margin: 0;
          text-align: center;
          width: 100%;
          margin-bottom: 25px;
          color: var(--green);
          margin-top: 10px;
          font-family: $font-family-5;
          @media (min-width: $breakpoint-mobile) {
            font-size: 30px;
            margin-top: 0;
            margin-bottom: 10px;
          }
        }
      }
      > .when-it-comes {
        font-size: 19px;
        font-weight: 600;
        color: var(--black);
        font-family: $font-fam;
        margin-bottom: 10px;
        @media (min-width: $breakpoint-mobile) {
          line-height: 50px;
          margin-top: 5%;
        }
        @media (min-width: $breakpoint-tablet) {
          font-size: 25px;
          margin-top: 2%;
        }
        > p {
          margin-bottom: 0;
          margin-top: 0;
          > .green-var {
            background-color: var(--green);
            padding: 5px 3px;
            border-radius: 10px;
            text-shadow: none;
            color: white;
            font-weight: 600;
            line-height: 42px;
            margin-right: 5px;
            @media(max-width: $breakpoint-xs) {
              margin-right: 2px;
              padding: 5px 0.5px;
            }
            @media (min-width: $breakpoint-mobile) {
              padding: 5px;
            }
          }
        }
        > .subtext {
          margin-top: 0;
          margin-bottom: 0;
          @media (min-width: $breakpoint-mobile) {
            margin-top: 1%;
          }
          @media (min-width: $breakpoint-tablet) {
            margin-top: 0;
          }
        }
      }
      > .question {
        padding: 0;
        margin-top: 0;
        @media (min-width: $breakpoint-mobile) {
          padding: 0 3%;
        }
        @media (min-width: $breakpoint-tablet) {
          padding: 0 8%;
          margin-top: 0;
        }
        > .main-q {
          line-height: 1.5em;
          font-size: 28px;
          font-weight: 700;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          margin-bottom: 10px;
          font-family: $font-fam;
          @media (min-width: $breakpoint-mobile) {
            font-size: 28px;
            margin-bottom: 10px;
          }
          @media (min-width: $breakpoint-tablet) {
            margin-top: 0;
            font-size: 28px;
          }
        }
      }
      > .clarify {
        padding: 0 2%;
        margin-top: 5px;
        @media (min-width: $breakpoint-mobile) {
          padding: 0;
        }
        @media (min-width: $breakpoint-tablet) {
          padding: 0 5%;
        }
        > .clarification {
        font-size: 18px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
        line-height: 1.7rem;
      }
      }
      > .contain {
        width: 100%;
        @media (min-width: $breakpoint-mobile) {
          width: 68%;
        }
        @media (min-width: $breakpoint-tablet) {
          width: 90%;
        }
        > .option-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media (min-width: $breakpoint-mobile) {
          justify-content: space-between;
        }
        @media (min-width: $breakpoint-tablet) {
          justify-content: center;
        }
      }
      }
    }
  }
  </style>
  