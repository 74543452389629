<template>
    <section id="testimonial">
        <div class="container">
            <h1 v-if="annuity_type === 'Growth'">We Have A Long History Of Helping Clients Throughout The Country Safely Grow And Protect Their Money</h1>
            <h1 v-else>We Have A Long History Of Helping Clients Throughout The Country Get Income-For-Life That You'll Never Outlive</h1>
            <div class="testimonial-section">
                <div class="row">
                    <div class="col-test col-12 col-md-6">
                        <div class="testimonial-item">
                            <ImageHolder class="img" imageName="Testimonial_1" altText="Testimonial 1" />
                        </div>
                    </div>
                    <div class="col-test col-12 col-md-6">
                        <div class="testimonial-item">
                            <ImageHolder class="img" imageName="Testimonial_2" altText="Testimonial 2" />
                        </div>
                    </div>
                    <div class="col-test col-12 col-md-6">
                        <div class="testimonial-item">
                            <ImageHolder class="img" imageName="Testimonial_3" altText="Testimonial 3" />
                        </div>
                    </div>
                    <div class="col-test col-12 col-md-6">
                        <div class="testimonial-item">
                            <ImageHolder class="img" imageName="Testimonial_4" altText="Testimonial 4" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ImageHolder from './comps/ImageHolder.vue';
export default {
    name: 'TestimonialSection',
    components: {
        ImageHolder,
    },
    computed: {
        annuity_type() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now' || this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'Income'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Growth'
            }
            return type;
        },
    }
}
</script>

<style lang="scss" scoped>
#testimonial {
    > .container {
        max-width: 1325px;
        > h1 {
        text-align: center;
        margin-bottom: 25px;
        font-family: $font-family-4;
        font-family: 'Inter', sans-serif !important;
        color: var(--black);
        font-weight: 700;
        font-size: 30px;
        padding-bottom: 20px;
        margin-top: 0;
        @media (min-width: $breakpoint-mobile) {
            font-size: 30px;
            margin-bottom: 0;
            padding: 0 10%;
        }
        @media (min-width: $breakpoint-tablet) {
            margin-bottom: 0;
            padding: 0 15%;
        }
    }
    > .testimonial-section {
        padding: 0 5%;
        > .row {
            > .col-test {
                padding: 0;
                > .testimonial-item {
                    padding: 0;
                    border-radius: 25px;
                    margin-bottom: 30px;
                    @media (min-width: $breakpoint-mobile) {
                        margin: 5%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        margin-bottom: 3em;
                    }
                    > .img {
                        width: 100%;
                        box-shadow: 1px 8px 10px 0px rgba(0,0,0,0.4);
                        -webkit-box-shadow: 1px 8px 10px 0px rgba(0,0,0,0.4);
                        -moz-box-shadow: 1px 8px 10px 0px rgba(0,0,0,0.4);
                        border-radius: 25px;
                    }
                }
            }
        }
    }
    }
}
</style>