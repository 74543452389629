<template>
      <img class="img"  :src="getImageSource()" :alt="altText" />
  </template>
  
  <script>
  export default {
    props: {
      imageName: String,
      altText: String,
    },
    methods: {
      getImageSource() {
        const supportsWebP = (() => {
          try {
            return document.createElement('canvas').toDataURL('image/webp').startsWith('data:image/webp');
          } catch (err) {
            return false;
          }
        })();
  
        if (supportsWebP) {
          return require(`../../assets/img/${this.imageName}.webp`);
        } else {
          return require(`../../assets/img/${this.imageName}.png`);
        }
      },
    },
  };
  </script>

  <style lang="scss">
    .img {
        width: auto;
        height: auto;
    }
  </style>
  