<template>
  <div class="q-card" @touchstart="handleTouchStart" @touchend="handleTouchEnd" :style="qCardStyle" v-for="opt in options" :key="opt" @click="$emit('answer', opt)" :class="{'isWrap' : opt.isWrap, 'isQ5' : opt.isQ5}">
      <SvgIcon v-if="opt.icon" :name="opt.icon" width="80" height="80" fillColor="currentColor" />
      <h1 class="text">{{ opt.text }}</h1>
      <h1 class="number" v-if="opt.number">{{ opt.number }}</h1>
  </div>
</template>

<script>
import SvgIcon from './SvgIcon.vue';
export default {
  props: {
     options: {
      type: Array,
     }
  },
  components: {
    SvgIcon,
  },
  computed: {
    qCardStyle() {
      return {
        'margin-top' : this.options[0].id === 'gender' ? '30px' : '25px',
      }
    }
  },
  data() {
    return {
    };
  },
  methods: {
    handleTouchStart(event) {
      event.currentTarget.classList.add('active');
    },
    handleTouchEnd(event) {
      event.currentTarget.classList.remove('active');
      // Execute your click logic here if needed
    },
  },
};
</script>

<style lang="scss" scoped>

.q-card {
  font-family: $font-family-2;
  transition: background-color 0.3s;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 240px;
  height: 150px;
  min-width: 140px;
  font-size: 14px;
  margin: 0 10px;
  line-height: 17px !important;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  background-color: #ffffffe3;
  color: #42a635;
  border-radius: 10px;
  padding: 0;
  margin-top: 10px;
  transition: .3s;
  border-style: solid;
  border-width: 2px;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout:none;
  &.active {
    background-color: #42a635; /* Change background color when active */
    color: var(--white) !important; /* Change text color */
  }
  > .text {
    margin-bottom: 0;
    margin-top: 3%;
    font-size: 20px;
  }
  > .number {
    font-family: $font-family-2;
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 0;
    font-weight: 800;
    @media (min-width: $breakpoint-mobile) {
        font-weight: 700;
        font-size: 38px;
    }
    @media (min-width: $breakpoint-tablet) {
        font-size: 38px;
        margin-bottom: 5%;
    }
  }
  @media (min-width: $breakpoint-xs) {
    font-family: $font-family-2;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    max-width: 240px;
    height: 150px;
    min-width: 170px;
    font-size: 14px;
    margin: 0 10px;
    line-height: 17px !important;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    background-color: #ffffffe3;
    color: #42a635;
    border-radius: 10px;
    padding: 0;
    margin-top: 10px;
    transition: .3s;
    border-style: solid;
    border-width: 2px;
  }
  @media (min-width: $breakpoint-mobile) {
      margin-top: 20px;
      width: 240px;
  }
  @media (min-width: $breakpoint-tablet) {
      margin-top: 25px;
      height: 150px;
      width: 240px;
      cursor: pointer;
  }
  &:hover {
    @media (min-width: $breakpoint-tablet) {
      background-color: #42a635;
      color: var(--white) !important;
      border-color: var(--white);
      fill: var(--white);
      > * {
        color: var(--white) !important;
      }
    }
  }
  
}
.isWrap {
  font-family: $font-family-2;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
  height: 150px;
  min-width: 135px;
  font-size: 14px;
  margin: 5px;
  line-height: 17px !important;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  background-color: #ffffffe3;
  color: #42a635;
  border-radius: 10px;
  padding: 0;
  margin-top: 10px;
  transition: .3s;
  border-style: solid;
  border-width: 2px;
  fill: #42a635;
  @media (min-width: $breakpoint-mobile) {
      margin-top: 20px;
      width: 29%;
      margin: 1.8%;
  }
  @media (min-width: $breakpoint-tablet) {
      width: 30%;
      margin: 1%;
  }
}
.isQ5 {
  @media (min-width: $breakpoint-mobile) {
      margin-top: 20px;
      margin: 2%;
      width: 240px;
      height: 180px;
  }
  @media (min-width: $breakpoint-tablet) {
      width: 240px;
      margin: 2% 4% 0;
      height: 180px;
  }
}
</style>
