<template>
  <div class="spinner-container">
    <span class="loader"></span>
  </div>
</template>

<style scoped>
.spinner-container {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 48px;
  height: 48px;
  border: 5px solid rgba(255, 255, 255, 0.4);
  border-bottom-color: #f06543;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Media query for smaller screens (e.g., mobile) */
@media (max-width: 768px) {
  .spinner-container {
    height: 100%;
  }
}
</style>
