<template>
    <section id="quiz" :class="{'d-block' : currentQuestionKey === 'Q7'}">
      <QuizQuestion
        :questionData="questionData[currentQuestionKey]"
        @answer="handleAnswer"
      />
      <InfoPage v-if="currentQuestionKey === 'Q7'" />
    </section>
  </template>
  
  <script>
  import InfoPage from './InfoPage.vue'
  import QuizQuestion from '../components/QuizQuestion.vue';
  import mixpanel from 'mixpanel-browser';
  import { mapActions } from 'vuex';
  export default {
    name: 'QuizPage',
    components: {
      QuizQuestion,
      InfoPage
    },
    created() {
      // Track the first step when the page is loaded
      this.dist_id = this.generateDistinctId();
      this.setId(this.dist_id);
      this.trackQuizProgress(1, this.questionData.length);
    },
    computed: {
      calculateDropOffRates() {
        const dropOffRates = [];

        for (let i = 0; i < this.steps.length - 1; i++) {
          const currentStepUsers = this.steps[i].users;
          const nextStepUsers = this.steps[i + 1].users;

          // Calculate drop-off rate as a percentage
          const dropOffRate = ((currentStepUsers - nextStepUsers) / currentStepUsers) * 100;

          dropOffRates.push({
            fromStep: this.steps[i].name,
            toStep: this.steps[i + 1].name,
            rate: dropOffRate.toFixed(2), // Limit to two decimal places
          });
        }

        return dropOffRates;
      },

    },
    data() {
      return {
        dist_id: '',
        steps: [
            { name: 'Step 1', users: 0 },
            { name: 'Step 2', users: 0 },
            { name: 'Step 3', users: 0 },
            { name: 'Step 4', users: 0},
            { name: 'Step 5', users: 0},
            { name: 'Step 6', users: 0},
        ],
        dropOffRates: [],
        questionData: {
            Q1: {
                firstq: true,
                title: 'See Which Annuities Can Maximize Your Retirement Income And Securely Grow Your Money Regardless of Market Conditions',
                question: 'Are You a Man or Woman?',
                clarification: '(The reason for asking, is because men and women have different payout factors based on their living expectancy)',
                options: [
                    { id: 'gender' , icon: 'man', value: 'man', text: 'Man', isWrap: false },
                    { id: 'gender', icon: 'woman', value: 'woman', text: 'Woman', isWrap: false },
                ]
            },
            Q2: {
                question: 'Which best describes your situation:',
                options: [
                    { id: 'marital_status', icon: 'rings', value: 'married', text: 'Married', isWrap: false },
                    { id: 'marital_status', icon: 'man', value: 'single', text: 'Single', isWrap: false },
                ]
            },
            Q3: {
                question: 'What Is Your Age Range?',
                options: [
                    { id: 'age', text: `I'm Under`, value: 'under 50', number:'50', isWrap: true, isQ5: false },
                    { id: 'age', text: `I'm Between`, value: 'between 50 and 54', number:'50 and 54', isWrap: true, isQ5: false },
                    { id: 'age', text: `I'm Between`, value: 'between 55 and 60', number:'55 and 60', isWrap: true, isQ5: false },
                    { id: 'age', text: `I'm Between`, value: 'between 60 and 64', number:'60 and 64', isWrap: true, isQ5: false },
                    { id: 'age', text: `I'm Between`, value: 'between 65 and 70', number:'65 and 70', isWrap: true, isQ5: false },
                    { id: 'age', text: `I'm Over`, value: 'over 70', number:'70', isWrap: true, isQ5: false },
                ]
            },
            Q4: {
                question: 'Are you retired?',
                options: [
                    { id: 'retired', icon: 'check', value: 'retired', text: 'Yes', isWrap: false },
                    { id: 'retired', icon: 'x', value: 'not retired', text: 'No', isWrap: false },
                ]
            },
            Q5: {
                question: `Which of the Following Categories Best Describes Why You Would Consider An Annuity The Most (if you had to pick one)?`,
                options: [
                    { id: 'categories', icon: 'money', value: 'income now', text: 'I Want Income Now', isWrap: true,  isQ5: true },
                    { id: 'categories', icon: 'vault', value: 'income later', text: 'I Want Income Later', isWrap: true, isQ5: true },
                    { id: 'categories', icon: 'plant', value: 'safe growth', text: 'I Want To Safely Grow My Money', isWrap: true, isQ5: true}
                ]
            },
            Q6: {
                question: 'Do you have at least $100,000 or more in your retirement portfolio?',
                clarification: '(The reason for asking, is because this annuity evaluation is specifically designed for investors that have at least $100K in their retirement savings portfolio).',
                options: [
                    { id: '100k', icon: 'check', value: '100k', text: 'Yes', isWrap: false },
                    { id: 'no-100k',icon: 'x', value: 'not 100k', text: 'No', isWrap: false },
                ]
            },
            Q7: {
              question: ''
            }
        },
        currentQuestionKey: 'Q1', // Initialize with the first question key
      };
    },
    methods: {
      ...mapActions(['saveAnswer', 'setId']), // Import the action you want to use

      // Define a method to track quiz completion
      trackQuizCompletion(totalSteps) {
        // Track quiz completion
        mixpanel.track('Quiz Completed', {
          totalQuestions: totalSteps,
        });

        // Perform other actions to mark the quiz as completed
      },
      sendEventData(eventData) {
        fetch('/mixpnl.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(eventData)
        })
        .then(response => {
          // Handle the response if needed
          console.log(response);
        })
        .catch(error => {
          // Handle any errors that occur during the fetch request
          console.error(error);
        });
      },
      generateDistinctId() {
        return Math.random().toString(36).substr(2, 9);
      },
      // Modify the trackQuizProgress function
      trackQuizProgress(currentStep, totalSteps) {
        // Extract UTM parameters from the URL
        function getUrlParams(url) {
          var params = {};
          var parser = document.createElement('a');
          parser.href = url;
          var query = parser.search.substring(1);
          var vars = query.split('&');
          for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            params[pair[0]] = decodeURIComponent(pair[1]);
          }
          return params;
        }

        const urlParams = getUrlParams(window.location.href);

        // Track the progress using Mixpanel
        const eventData = {
          event: "Annuity Evaluation E / Quiz Steps",
          properties: {
            currentStep: currentStep,
            totalSteps: totalSteps,
            time: Date.now() / 1000, 
            distinct_id: this.dist_id, 
            $browser: navigator.userAgent, 
            $current_url: window.location.href,
            $initial_referrer: document.referrer.split('/')[2], 
            $os: navigator.platform,
            $referrer: document.referrer,
            $screen_height: window.screen.height,
            $screen_width: window.screen.width,
          }
        }

        const eventDataStep7 = {
          event: "Annuity Evaluation E / Lead Capture",
          properties: {
            currentStep: currentStep,
            totalSteps: totalSteps,
            time: Date.now() / 1000, 
            distinct_id: this.dist_id, 
            $browser: navigator.userAgent, 
            $current_url: window.location.href,
            $initial_referrer: document.referrer.split('/')[2], 
            $os: navigator.platform,
            $referrer: document.referrer,
            $screen_height: window.screen.height,
            $screen_width: window.screen.width,
          }
        }

        // If UTM parameters exist in the URL, add them to the eventData
        if (urlParams.utm_source) {
          eventData.properties.utm_source = urlParams.utm_source;
          eventDataStep7.properties.utm_source = urlParams.utm_source;
        }
        if (urlParams.utm_medium) {
          eventData.properties.utm_medium = urlParams.utm_medium;
          eventDataStep7.properties.utm_medium = urlParams.utm_medium;
        }
        if (urlParams.utm_campaign) {
          eventData.properties.utm_campaign = urlParams.utm_campaign;
          eventDataStep7.properties.utm_campaign = urlParams.utm_campaign;
        }
        if (urlParams.utm_term) {
          eventData.properties.utm_term = urlParams.utm_term;
          eventDataStep7.properties.utm_term = urlParams.utm_term;
        }
        if (urlParams.utm_content) {
          eventData.properties.utm_content = urlParams.utm_content;
          eventDataStep7.properties.utm_content = urlParams.utm_content;
        }

        // Send the appropriate event data
        if (currentStep !== totalSteps) {
          this.sendEventData(eventData);
        } else if (currentStep === totalSteps) {
          this.sendEventData(eventDataStep7);
        }
      },

      handleAnswer(answer) {
        // Scroll to the top of the page after answering
      window.scrollTo(0, 0);
      // Move to the next question after answering
      const questionKeys = Object.keys(this.questionData);
      const currentIndex = questionKeys.indexOf(this.currentQuestionKey);

      // Increment the number of users at the current step
      this.steps[currentIndex].users++;

      // Save the answer to Vuex using the saveAnswer action
      this.saveAnswer({ questionKey: this.currentQuestionKey, answer });

      // Track the user's progress using Mixpanel
      this.trackQuizProgress(currentIndex + 2, questionKeys.length);

      if (currentIndex < questionKeys.length - 1) {
        // If not the last question, move to the next question
        this.currentQuestionKey = questionKeys[currentIndex + 1];
      } else {
        // If it's the last question, track quiz completion
        //this.trackQuizCompletion(questionKeys.length);
      }
    },

    },
  };
  </script>

  <style lang="scss" scoped>
    #quiz {
        display: flex;
        justify-content: center; /* Horizontally center-aligns the content */
        align-items: center; /* Vertically center-aligns the content */
        width: 100%; /* Optional: Set the width as needed */
        padding-top: 0;
        @media (min-width: $breakpoint-mobile) {
          padding-top: 5px;
        }
    }

  </style>
  