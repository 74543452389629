<template>
    <div class="custom-accordion">
      <div
        v-for="(item, index) in itemsCopy"
        :key="index"
        class="accordion-item"
      >
        <div class="accordion-header" @click="toggleItem(index)">
          <div class="row">
            <div class="col-11">
              <p>{{ item.title }}</p>
            </div>
            <div class="col-1">
              <SvgIcon
                class="accordion-arrow"
                :class="{'active' : item.open, 'close' : !item.open}"
                name="chevronDown"
                fill-color="var(--gray)"
                width="20"
                height="20" />
              <!-- <img class="accordion-arrow" src="../../assets/svg/chevron-down.svg" alt="" :class="{'active' : item.open, 'close' : !item.open}"> -->
            </div>
          </div>
        </div>
        <div class="accordion-content" v-show="item.open">
          {{ item.content }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import SvgIcon from '../SvgIcon.vue';
  export default {
    name: 'TextAccordion',
    components: {
      SvgIcon,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        itemsCopy: this.items.map(item => ({ ...item })), // Create a copy of the prop
      };
    },
    methods: {
      toggleItem(index) {
        this.itemsCopy[index].open = !this.itemsCopy[index].open;
      },
    },
  };
  </script>

<style lang="scss" scoped>
/* Add your custom CSS styles here */
.custom-accordion {
    margin: 0;
    color: #298B99;
    cursor: pointer;
    padding: 0;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    font-size: 24px;
    font-family: $font-family-5;
    @media (min-width: $breakpoint-mobile) {
      padding: 20px;
    }
    @media (min-width: $breakpoint-tablet) {
      padding: 35px;
      padding-top: 0;
    }
}

.accordion-item {
  margin-bottom: 10px;
  border-top: 1px solid #298B99;
}

.accordion-header {
  cursor: pointer;
  background-color: transparent;
  padding: 15px;
  padding-left: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-size: 16px;
  @media (min-width: $breakpoint-mobile) {
    font-size: 18px;
  }
  @media (min-width: $breakpoint-tablet) {
    font-size: 24px;
  }
  > .row {
    width: 100%;
    align-items: center;
    > .col-11 {
      padding-left: 0;
      display: flex;
      justify-content: flex-start;
      > p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 16px;
        @media (min-width: $breakpoint-mobile) {
          font-size: 24px;
        }
        @media (min-width: $breakpoint-tablet) {
          font-size: 24px;
        }
      }
    }
    > .col-1 {
      padding-right: 0;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.accordion-arrow {
    width: 20px;
    height: 20px;
    @media (min-width: $breakpoint-mobile) {
      width: 30px;
      height: 30px;
    }
    &.active {
      animation: rotate2 0.25s cubic-bezier(0.42, 0, 0.58, 1) forwards;
      transform-origin: center center;
    }
    &.close {
      animation: rotateBack2 0.25s cubic-bezier(0.42, 0, 0.58, 1) forwards;
      transform-origin: center center;
    }
  }

  @keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes rotateBack2 {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}



.accordion-content {
  padding: 0 20px;
  text-align: left;
  font-size: 18px !important;
  color: #3a3a3a !important;
  border-top: none;
  border-radius: 0 0 5px 5px;
  background-color: transparent;
  line-height: 1.7;
  font-family: $font-fam;
}
</style>
  