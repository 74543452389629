<template>
    <section id="info">
        <FormSection />
        <CompanyLogos />
        <StepsSection />
        <CTASection v-if="annuity_type === 'Growth'" question="What Makes Annuity Gator Different?" answer1="We simplify the way you research “protected growth” annuities so you can protect your money from market losses while still being able to earn a reasonable rate of return." answer2="During your Annuity Evaluation, you’ll be able to easily review personalized annuity recommendations from the comfort and convenience of your own home - right over the phone." answer3="We take your retirement happiness and security very seriously, and we will provide you with independent annuity guidance and honest recommendations so you can retire with confidence." img="3-images" :is2="false" />
        <CTASection v-else question="What Makes Annuity Gator Different?" answer1="We simplify the way you research income annuities so you can make an informed decision to secure your dream retirement with income that lasts as long as you do." answer2="During your Annuity Evaluation, you’ll be able to easily review personalized annuity recommendations from the comfort and convenience of your own home - right over the phone." answer3="We take your retirement happiness and security very seriously, and we will provide you with independent annuity guidance and honest recommendations so you can retire with confidence." img="3-images" :is2="false" />
        <CTASection v-if="annuity_type === 'Growth'" question="About Annuity Gator" answer1="Launched in 2012, Annuity Gator is the original annuity “investigator”. Annuity Gator has a long history of helping clients financially prepare for retirement with the proper use of annuities. Our research team evaluates the top annuities that offer the highest secure growth rates and benefits." answer2="Using virtual phone consultations, we help clients evaluate hundreds of annuities from top-rated insurance carriers - without ever having to leave their home. Our technology-driven approach can help you find the best annuity that pays top dollar." img="rates" title="Annuity Gator Is Highly Rated By Our Clients" :is2="true" />
        <CTASection v-else question="About Annuity Gator" answer1="Launched in 2012, Annuity Gator is the original annuity “investigator”. Annuity Gator has a long history of helping clients financially prepare for retirement with the proper use of annuities. Our research team evaluates the top annuities that offer the highest guaranteed income and benefits." answer2="Using virtual phone consultations, we help clients evaluate hundreds of annuities from top-rated insurance carriers - without ever having to leave their home. Our technology-driven approach can help you find the best annuity that pays top dollar." img="rates" title="Annuity Gator Is Highly Rated By Our Clients" :is2="true" />
        <div class="vid-tes-section">
            <VideoSection />
            <TestimonialSection />
            <AccordionSection @goTo="goToForm" />
        </div>
        
    </section>
</template>

<script>
import FormSection from '@/components/FormSection.vue'
import StepsSection from '@/components/StepsSection.vue'
import CompanyLogos from '@/components/CompanyLogos.vue'
import CTASection from '@/components/CTASection.vue'
import TestimonialSection from '@/components/TestimonialSection.vue'
import VideoSection from '@/components/VideoSection.vue'
import AccordionSection from '@/components/AccordionSection.vue'
export default {
    name: 'InfoPage',
    components: {
        FormSection,
        StepsSection,
        CompanyLogos,
        CTASection,
        TestimonialSection,
        VideoSection,
        AccordionSection
    },
    computed: {
        userAnswers() {
            return this.$store.state.answers;
        },
        annuity_type() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now' || this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'Income'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Growth'
            }
            return type;
        },
    },
    mounted() {
        // Load Wistia script
        const wistiaScript = document.createElement('script');
        wistiaScript.src = 'https://fast.wistia.net/assets/external/E-v1.js';
        wistiaScript.async = true;
        wistiaScript.defer = true; // Add defer attribute
        document.body.appendChild(wistiaScript);
    },
    methods: {
        goToForm() {
            document.getElementById('form-section').scrollIntoView({ behavior: 'smooth' })
        }
    }
}
</script>

<style lang="scss" scoped>
#info {
    > .vid-tes-section {
        background-image: url('../assets/img/bg/bottomn.webp');
        background-repeat: no-repeat;
        background-position: top;
        background-size: cover;
    }
}
</style>