<template>
    <section id="accordion">
        <div class="accordion-container container">
            <div class="text-section">
                <h1 class="title">Frequently Asked Questions <img class="icon" src="../assets/img/question-icon.png" alt="Question Mark Icon"></h1>
                
            </div>
            <div class="accordion-section">
                <TextAccordion :items="accordionItems" />
            </div>
            <div class="btn-section">
                <button @click="$emit('goTo')" type="button" class="accordion-btn">GET FREE EVALUATION</button>
            </div>
        </div>
    </section>
  </template>
  
  <script>
  import TextAccordion from '../components/comps/TextAccordion.vue';
  
  export default {
    components: {
    TextAccordion
},
    data() {
      return {
        accordionItems: [
          {
            title: 'Who is Annuity Gator?',
            content: 'Launched in 2012, Annuity Gator is the original annuity “investigator”. We have a long history of helping clients achieve their retirement goals with the proper use of annuities. No overwhelming annuity whitepapers or slick company brochures. No high-pressure sales tactics. Annuity Gator provides clients with honest annuity guidance and personalized annuity recommendations from the comfort and convenience of their home.',
            open: false, // Initially closed
          },
          {
            title: 'What’s included in an Annuity Evaluation phone call?',
            content: `An Annuity Evaluation is a 30-minute confidential phone conversation with one of our licensed annuity specialists that will show you how to reach your retirement goals, protect your assets, and do so with the lowest cost and least amount of risk. We'll answer any questions that you may have, and you won't be pressured to buy anything. Everything is done 100% over the phone. No one will be coming to your house, and you will not be required to drive to a financial advisor's office.`,
            open: false, // Initially closed
          },
          {
            title: 'What makes Annuity Gator different?',
            content: 'Our team of licensed financial professionals are annuity specialists. We are not like many other financial advisors that are classified as “generalists.” A generalist financial advisor typically offers traditional financial planning services and may not have the expertise to help clients who are retired or approaching retirement. We focus specifically on developing “safe money” annuity strategies to ensure that our clients NEVER run out of money during retirement while helping to protect our client’s nest egg.',
            open: false, // Initially closed
          },
          {
            title: 'Will I be pressured to buy anything during my phone call?',
            content: `No, that's the worst! We do not like to be pressured to buy anything — and neither should you. We help you identify the top annuities that are best for you based on your age and several other factors. Many of the folks that we have helped end up becoming happy clients; however, you are under no obligation to work with us — and you will not be pressured to buy anything. The choice is entirely up to you.`,
            open: false, // Initially closed
          },
          {
            title: 'Does this service have a cost?',
            content: 'We provide Annuity Evaluations for free. Our licensed financial specialists can help you compare several annuities against each other so that you can decide if an annuity is right for you. If you would like to purchase an annuity through us, the insurance company that provides that annuity compensates us for helping you find the best annuity. In the spirit of full transparency, we offer our Annuity Evaluation phone consultations for free because we know that if we educate and help enough people — many of them will choose to work with us and become happy clients.',
            open: false, // Initially closed
          },
          {
            title: 'How does Annuity Gator get compensated?',
            content: 'Our proprietary process can help you ensure a comfortable and financially secure retirement. If we can add value to you and put more money in your pocket — you may then consider purchasing an annuity through us. The insurance company that offers the annuity that you select will then compensate us for helping you find the right annuity. Annuity Gator exclusively works with a select group of vetted, independent advisors that specialize in delivering sound retirement planning strategies and unbiased annuity recommendations; we are not beholden to any particular insurance company.',
            open: false, // Initially closed
          },
          {
            title: 'What if I already have a financial advisor?',
            content: 'Many of the clients we have helped have been working with a financial advisor for many years and want a second opinion regarding their retirement and financial plan. Since we’re talking about something as important as your retirement finances, we advise clients to get an objective, independent second opinion before making an irreversible decision — that may have devastating consequences during their retirement years. ',
            open: false
          }
        ],
      };
    },
  };
  </script>

  <style lang="scss" scoped>
    #accordion {
        > .accordion-container {
          max-width: 1500px;
            margin: 0 auto;
            padding: 0 2%;
            @media (min-width: $breakpoint-mobile) {
                padding: 0 4%;
            }
            > .text-section {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            > .title {
                font-family: $font-family-4;
                font-size: 30px;
                color: #298B99;
                font-weight: 700;
                margin-left: 2%;
                margin-top: 0;
                > .icon {
                width: 60px;
                height: 60px;
                margin-left: 10px;
                margin-bottom: -4%;
            }
            }
            
        }
        > .accordion-section {
            width: 100%;
            margin-top: 5%;
            @media (min-width: $breakpoint-mobile) {
                margin-top: 0%;
            }
        }
        > .btn-section {
          margin-top: 3%;
          margin-bottom: 20%;
          @media (min-width: $breakpoint-mobile) {
            margin-bottom: 8%;
            padding: 0 15%;
          }
          @media (min-width: $breakpoint-tablet) {
            margin-bottom: 0%;
          }
            > .accordion-btn {
                cursor: pointer;
                border: none;
                margin-top: 15%;
                background-color: #FFB500;
                color: white;
                font-size: 22px;
                border-radius: 10px;
                font-weight: 800;
                padding: 15px;
                transition: 0.3s;
                width: 100%;
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                font-family: $font-family-2;
                margin-bottom: 3%;
                @media (min-width: $breakpoint-mobile) {
                    margin-top: 0;
                }
                @media (min-width: $breakpoint-tablet) {
                  width: 500px;
                }
                &:hover {
                    background-color: rgb(215, 170, 5);
                }
            }
        }
    }
}
  </style>
  