<template>
    <section id="thanks">
        <div class="container">
            <h1 class="title">Thank you...</h1>
            <div class="thanks-container">
                <div class="thanks-card">
                <p class="thanks-text text-1">Our Annuity Evaluation is only available to investors with a minimum of $100,000 or more in their retirement portfolio.</p>
                <p class="thanks-text text-2">If you have any questions, you may reach us at <a href="mailto:gator@annuitygator.com" style="outline: none;" target="_blank">gator@annuitygator.com</a></p>
                <p>Thank You, 
                    <br>
                    The Annuity Gator Team
                </p>
            </div>
            </div>
            <div class="copy">
                <div class="copy-text">
                    <p class="rights">
                    Before purchasing any investment product be sure to do your own due diligence and consult a properly licensed professional should you have specific questions as they relate to your individual circumstances. All names, marks, and materials used for the reviews on this site are property of their respective owners, and not those of AnnuityGator.com. Annuity product guarantees rely on the financial strength and claims-paying ability of the issuing insurer.
                    <br>
                    Annuity riders may be available for an additional annual premium that can provide additional benefits and income guarantees. By contacting us you may speak with an insurance licensed agent in your state, and you may
                    <br>
                    be offered insurance products for sale.
                </p>
                <p class="rights">
                    AnnuityGator.com. All Rights Reserved | <a href="https://www.annuitygator.com/privacy-policy/">Privacy Policy</a>
                </p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'ThankYou',
    data() {
        return {
            dist_id: '',
        }
    },
    created() {
        this.dist_id = this.generateDistinctId();
        if(this.$route.path === '/thank-annuity-evaluation') {
            // Track the first step when the page is loaded
            this.trackQuizCompletion('Qualified');
        } else if(this.$route.path === '/not-qualified') {
            // Track the first step when the page is loaded
            this.trackQuizCompletion('Not Qualified');
        }
    },
    methods: {
        generateDistinctId() {
            return Math.random().toString(36).substr(2, 9);
        },
        sendEventData(eventData) {
            fetch('/mixpnl.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(eventData)
            })
            .then(response => {
            // Handle the response if needed
            console.log(response);
            })
            .catch(error => {
            // Handle any errors that occur during the fetch request
            console.error(error);
            });
        },
        trackQuizCompletion(quizCompletion) {
            // Extract UTM parameters from the URL
            function getUrlParams(url) {
                var params = {};
                var parser = document.createElement('a');
                parser.href = url;
                var query = parser.search.substring(1);
                var vars = query.split('&');
                for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=');
                params[pair[0]] = decodeURIComponent(pair[1]);
                }
                return params;
            }

            const urlParams = getUrlParams(window.location.href);

            // Generate a distinct ID
            //let distinct_id = this.generateDistinctId();

            // Track the quiz completion using Mixpanel
            const eventData = {
                event: "Annuity Evaluation / Self Schedule Page",
                properties: {
                quizStatus: quizCompletion,
                time: Date.now() / 1000, 
                distinct_id: this.dist_id, 
                $browser: navigator.userAgent, 
                $current_url: window.location.href,
                $initial_referrer: document.referrer.split('/')[2], 
                $os: navigator.platform,
                $referrer: document.referrer,
                $screen_height: window.screen.height,
                $screen_width: window.screen.width,
                }
            }

            // If UTM parameters exist in the URL, add them to the eventData
            if (urlParams.utm_source) {
                eventData.properties.utm_source = urlParams.utm_source;
            }
            if (urlParams.utm_medium) {
                eventData.properties.utm_medium = urlParams.utm_medium;
            }
            if (urlParams.utm_campaign) {
                eventData.properties.utm_campaign = urlParams.utm_campaign;
            }
            if (urlParams.utm_term) {
                eventData.properties.utm_term = urlParams.utm_term;
            }
            if (urlParams.utm_content) {
                eventData.properties.utm_content = urlParams.utm_content;
            }

            // Send the event data to Mixpanel
            this.sendEventData(eventData);

            // Perform other actions to mark the quiz as completed
        },
    },
}
</script>

<style lang="scss" scoped>
#thanks {
    width: 100%;
    > .container {
        max-width: 1560px;
        padding: 15px 0;
        @media (min-width: $breakpoint-tablet) {
            padding: 15px;
        }
        > .title {
            font-family: $font-family-3;
            font-size: 45px;
            font-weight: normal;
            margin-bottom: 16px;
            margin-top: 1.5%;
            color: var(--black);
            margin-top: 0;
        }
        > .thanks-container {
            font-family: $font-family-2;
            width: 100%;
            display: flex;
            justify-content: center;
            > .thanks-card {
            width: 100%;
            line-height: 1.3;
            text-align: left;
            padding: 18px 40px;
            overflow: hidden;
            background-clip: padding-box;
            box-shadow: rgba(0,0,0,0.4) 0px 8px 12px -4px;
            background-color: rgb(244,250,243) !important;
            font-size: 16px;
            color: var(--black);
            @media (min-width: $breakpoint-mobile) {
                padding: 8% 14%;
            }
            @media (min-width: $breakpoint-tablet) {
                width: 43%;
                padding: 6% 8%;
            }
            > .thanks-text {
                margin-bottom: 30px;
                > a {
                    color: var(--black);
                }
                &.text-1 {
                    margin-bottom: 8%;
                    @media (min-width: $breakpoint-mobile) {
                        margin-bottom: 3%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        margin-bottom: 3%;
                    }
                }
                &.text-2 {
                    margin-bottom: 13%;
                    @media (min-width: $breakpoint-mobile) {
                        margin-bottom: 7%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        margin-top: 0;
                    }
                }
            }
        }
        }
        > .copy {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            line-height: 1.5;
            margin-top: 50%;
            @media (min-width: $breakpoint-mobile) {
                margin-top: 25%;
            }
            @media (min-width: $breakpoint-tablet) {
                margin-top: 13.5%;
            }
            > .copy-text {
                padding: 0 2%;
                @media (min-width: $breakpoint-mobile) {
                    padding: 0 10%;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding: 0 28%;
                }
                > .rights {
                width: 100%;
                text-align: center;
                font-size: 8px;
                margin-top: 10px;
                color: rgb(195,195,195) !important;
                font-weight: 400 !important;
                @media (min-width: $breakpoint-mobile) {
                    font-size: 12px;
                }
                > a {
                    color: var(--black);
                }
            }
            }
        }
    }
}
</style>