<template>
    <section id="navbar" :class="{'d-none' : isThanks}">
        <div v-if="isDesktop && !isThanks2" class="row">
            <div class="nav-item col-1 col-logo">
                <ImageHolder class="logo" imageName="logo" altText="Annuity Gator Logo" />
            </div>
            <div class="nav-item col-8 col-img">
                <ImageHolder class="text-img" imageName="access-icon" altText="Access To Hundreds Of Annuities From Top Rated Companies *" />
                <ImageHolder class="text-img" imageName="excellent-icn" altText="Rated Excellent Based on 300+ Client Reviews *" />
                <ImageHolder class="text-img" imageName="serving-icn" altText="Serving Clients With $100k+ in Retirement Savings" />
            </div>
            <div class="nav-item col-custom">
                <div class="button">
                    <a class="btn green-btn" href="tel:8884402468">
                        <div class="row">
                            <div class="col-12">
                                <p>Questions?</p>
                            </div>
                            <div class="col-12">
                                <img src="../assets/svg/phone-solid.svg" alt="">
                                <p class="number">Call us 888-440-2468</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-else-if="isTablet && !isThanks2" class="row" style="margin-bottom: 20px;">
            <div class="nav-item col-4">
                <ImageHolder class="logo" imageName="logo" altText="Annuity Gator Logo" />
            </div>
            <div class="nav-item col-4">
                <div class="button">
                    <a class="btn green-btn" href="tel:8884402468">
                        <div class="row">
                            <div class="col-12">
                                <p>Questions?</p>
                            </div>
                            <div class="col-12">
                                <img src="../assets/svg/phone-solid.svg" alt="">
                                <p class="number">Call us 888-440-2468</p>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div v-else-if="isThanks2" class="row split-test">
            <div class="nav-item col-12 col-md-4">
                <ImageHolder class="logo" imageName="logo" altText="Annuity Gator Logo" />
            </div>
            <div class="nav-item col-12 col-md-4">
                <div class="button">
                    <a class="btn green-btn" href="tel:8884402468">
                        <img src="/annuity-evaluation-e/img/phone-solid.b5bc887f.svg" alt="">
                        <p class="number">888-440-2468</p>
                    </a>
                </div>
            </div>
        </div>
        <div v-else class="container">
            <div  class="row">
            <div class="col-12">
                <ImageHolder class="logo" imageName="logo" altText="Annuity Gator Logo" />
            </div>
        </div>
        </div>
    </section>
</template>

<script>
import ImageHolder from './comps/ImageHolder.vue';
export default {
    name: 'NavBar',
    components: {
        ImageHolder,
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 992; // Adjust the breakpoint value as needed
        },
        isTablet() {
            return this.windowWidth >= 770 && this.windowWidth < 992; // Adjust the breakpoint value as needed
        },
        isThanks() {
            if(this.$route.path === '/not-qualified' || this.$route.path === '/thank-annuity-evaluation') {
                return true;
            } else {
                return false;
            }
        },
        isThanks2() {
            let route = false
            if(this.$route.path === '/thank-annuity-evaluation-e') {
                route = true;
            } else {
                route = false;
            }
            return route;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
    },
}
</script>

<style lang="scss" scoped>
.split-test {
    padding: 5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    margin-top: 2% !important;
    @media (min-width: $breakpoint-mobile) {
        flex-direction: row;
    }
    > .nav-item {
        > .logo {
            width: 250%;
            max-width: 180px;
            margin-top: 2%;
            @media (min-width: $breakpoint-tablet) {
                margin-bottom: 20px;
            }
        }
        > .button {
            margin-top: 2%;
            > .green-btn {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 20px 30px;
                > img {
                    width: 15px;
                    margin: 0 5px;
                    display: inline;
                }
                > p {
                    margin: 0;
                    font-size: 16px;
                }
            }
        }
    }
}
#navbar {
    width: 100%;
    height: 80%;
    min-height: 60px;
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    max-width: 1250px;
    > .row {
        padding: 0;
        width: inherit;
        display: flex;
        justify-content: space-between;
        @media (min-width: $breakpoint-mobile) {
            padding: 0 5%;
            margin-top: 3%;
            justify-content: center;
            align-items: flex-start;
        }
        @media (min-width: $breakpoint-tablet) {
            padding: 0;
            margin-top: 0;
            justify-content: space-between;
            align-items: center;
        }
        > .col-img {
            display: flex;
            flex-direction: row;
            @media (min-width: $breakpoint-tablet) {
                justify-content: space-evenly !important;
                margin-left: 5%;
            }
            > .img {
                width: 23% !important;
                padding-left: 3% !important;
            }
        }
        > .col-logo {
            justify-content: start !important;
            > .logo {
                padding-left: 10%;
            }
        }
        > .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        &.col-custom {
            flex: 0 0 auto;
            width: 19.666667%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            > .button {
                display: flex;
                > .green-btn {
                    text-decoration: none;
                    background-color: var(--green);
                    flex-wrap: wrap;
                    cursor: pointer;
                    border: none;
                    border-radius: 10px;
                    width: 120%;
                    padding: 5px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 700;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $font-fam;
                    @media (min-width: $breakpoint-mobile) {
                        //font-size: 18px;
                    }
                    &:hover {
                        background-color: var(--green-hover);
                    }
                    > .row > .col-12 {
                        padding-left: 0;
                        padding-right: 0;
                        > img {
                            width: 15px;
                            margin: 0 5px;
                            display: inline;
                            @media (min-width: $breakpoint-mobile) {
                                width: 20px;
                            }
                        }
                        > .number {
                            display: inline;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
        > .logo {
            width: 250%;
            max-width: 180px;
            margin-top: 2%;
            @media (min-width: $breakpoint-tablet) {
                margin-bottom: 20px;
            }
        }
        > .button {
                display: flex;
                > .green-btn {
                    text-decoration: none;
                    background-color: var(--green);
                    flex-wrap: wrap;
                    cursor: pointer;
                    border: none;
                    border-radius: 10px;
                    width: 120%;
                    padding: 5px 15px;
                    color: #fff;
                    font-size: 15px;
                    font-weight: 700;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-family: $font-fam;
                    @media (min-width: $breakpoint-mobile) {
                        font-size: 19px;
                    }
                    &:hover {
                        background-color: var(--green-hover);
                    }
                    > .row > .col-12 {
                        padding-left: 0;
                        padding-right: 0;
                        > img {
                            width: 15px;
                            margin: 0 5px;
                            display: inline;
                            @media (min-width: $breakpoint-mobile) {
                                width: 20px;
                            }
                        }
                        > .number {
                            display: inline;
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
    }
    }
    > .container {
        padding: 15px 15px 0;
        > .row > .col-12 {
        display: flex;
        align-items: center;
        justify-content: center;
        > .logo {
            width: 100%;
            max-width: 180px;
            margin-bottom: 10px;
            margin-top: 15px;
        }
    }
    }
    > .row > .col-2 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > .text-img {
            width: 120%;
            max-width: 185px;
            padding-right: calc(1.5rem * .5);
            padding-left: calc(1.5rem * .5);
        }
    }
}
</style>