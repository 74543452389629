<template>
  <div id="app">
    <NavBar />
    <router-view></router-view>
    <FooterSection></FooterSection>
    <FormLoader v-if="isLoading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import NavBar from './components/NavBar.vue'
import FooterSection from './components/FooterSection.vue'
import FormLoader from './components/comps/FormLoader.vue';
export default {
  name: 'App',
  components: {
    NavBar,
    FooterSection,
    FormLoader,
  },
  created() {
    this.getUTMs();
    this.setFormData(this.formData)
  },
  mounted() {
    // Load Google Tag Manager script
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-K3NHQ3');`;
    document.head.appendChild(gtmScript);

    // Google Tag Manager (noscript)
    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K3NHQ3"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(gtmNoScript);
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
  },
  data() {
    return {
      formData: {
                utm_source: "",
                utm_medium: "",
                utm_campaign: "",
                utm_term: "",
                utm_content: "",
                gclid: ""
            },
    }
  },
  methods: {
    ...mapActions(['setFormData']),
    getUTMs() {
            const urlParams = new URLSearchParams(window.location.search);
            const utmFields = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
            utmFields.forEach(field => {
            if (urlParams.has(field)) {
                this.formData[field] = urlParams.get(field);
            }
            });

            if (urlParams.has('gclid')) {
              this.formData['gclid'] = urlParams.get('gclid');
            }
        },
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: hidden;
}
</style>
