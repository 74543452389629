// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import Quiz from './views/QuizPage.vue';
import Thanks from './views/ThankYouPage.vue';
const routes = [
  {
    path: '/annuity-evaluation-e',
    alias: ['/annuity-evaluation-nt', '/annuity-evaluation'],
    name: 'annuity-evaluation-e',
    component: Quiz,
    meta: {
      title: 'See Which Annuities Can Maximize Your Retirement Income And Securely Grow Your Money Regardless of Market Conditions', // Set the title for the home route
    },
  },
  {
    path: '/not-qualified',
    name: 'thanks',
    component: Thanks,
    meta: {
      title: 'Annuity Evaluation – Not Qualified - Annuity Quiz', // Set the title for the home route
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // Scroll to the top of the page
  console.log('to', to);
  console.log('from', from);
  window.scrollTo(0, 0);
  document.title = to.meta.title || 'Annuity Gator';
  // Continue to the next route
  next();
});

export default router;
