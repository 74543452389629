<template>
    <section id="form" :class="{'bg-cover' : isDesktop}">
        <div class="form-container container">
            <div class="row">
                <div class="text-section col-12 col-md-6">
                    <h1 class="title">You Qualify For A Free {{ annuity_type }} Annuity Evaluation</h1>
                    <h1 class="affirmation" v-html="annuity_type_text"></h1>
                    <ul class="checks">
                        <li>
                            <div class="text-section">
                                <div class="row mb-sm-3">
                                    <div class="col-1">
                                        <img class="check" src="../assets/svg/circle-check-regular.svg" alt="">
                                    </div>
                                    <div class="col-11">
                                        <p>Calculate precisely how much you really need to comfortably retire.</p>
                                    </div>
                                </div>
                                
                            </div>
                            
                        </li>
                        <li>
                            <div class="text-section">
                                <div class="row mb-sm-3">
                                    <div class="col-1">
                                        <img class="check" src="../assets/svg/circle-check-regular.svg" alt="">
                                    </div>
                                    <div class="col-11">
                                        <p>Get customized annuity quotes and side- by-side comparisons from top-rated companies prepared by licensed annuity specialists.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="text-section">
                                <div class="row mb-sm-3">
                                    <div class="col-1">
                                        <img class="check" src="../assets/svg/circle-check-regular.svg" alt="">
                                    </div>
                                    <div class="col-11" v-if="annuity_type === 'Safe Growth'">
                                        <p>See how to safely grow and protect a portion of your money based on your unique situation.</p>
                                    </div>
                                    <div class="col-11" v-else>
                                        <p>See how to get ever-increasing retirement income that you cannot outlive during retirement based on your unique situation.</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="text-section">
                                <div class="row mb-sm-3">
                                    <div class="col-1">
                                        <img class="check" src="../assets/svg/circle-check-regular.svg" alt="">
                                    </div>
                                    <div class="col-11">
                                        <p>Purchase an annuity confidently from the comfort and convenience of your own home.</p>
                                    </div>
                                </div>
                                
                            </div>
                        </li>
                    </ul>
                    <div class="complete-form">
                        <h1 class="complete" v-if="isDesktop">Complete the form and get your free Annuity Evaluation by a licensed Annuity Gator Professional…</h1>
                        <img  v-if="isDesktop" class="arrow" src="../assets/img/arrow-short.png" alt="">
                    </div>
                </div>
                <div class="form-section col-12 col-md-6">
                    <div class="form-cont">
                        <h1 class="complete" v-if="!isDesktop">Complete the form and get your free Annuity Evaluation by a licensed Annuity Gator Professional…</h1>
                    <form @submit.prevent="submitForm" id="form-section">
                        <p>Final Step: Fill out the form below to get your
                            <!-- <br v-if="isDesktop"> -->
                            <b class="free">Free "{{annuity_type_form}}" Annuity Evaluation</b>
                        </p>
                        <div class="row">
                            <div class="form-input col-12 col-lg-6">
                                <label for="first_name">First Name *</label>
                                <input v-model="formData.first_name" pattern="[A-Za-z\s]+" type="text" name="first_name" required>
                                <label v-if="fnameEmpty" class="error" for="email">Required Field</label>
                            </div>
                            <div class="form-input col-12 col-lg-6">
                                <label for="last_name">Last Name *</label>
                                <input v-model="formData.last_name" pattern="[A-Za-z\s]+" type="text" name="last_name" required>
                                <label v-if="lnameEmpty" class="error" for="email">Required Field</label>
                            </div>
                            <div class="form-input col-12">
                                <label for="email">Email Address *</label>
                                <input v-model="formData.email" type="email" name="email" required>
                                <label v-if="emailInvalid" class="error" for="email">Invalid Email</label>
                            </div>
                            <div class="form-input col-12">
                                <label for="phone">Phone Number *</label>
                                <input v-model="formData.phone" type="tel" name="phone" maxlength="14" pattern="\(\d{3}\) \d{3}-\d{4}" @input="formatInput" ref="phoneInput">
                                <label v-if="phoneInvalid" class="error" for="phone">Invalid Phone</label>
                            </div>
                            <div class="form-input col-12">
                                <label for="concerned_living_savings">How concerned are you about outliving your retirement savings? *</label>
                                <select v-model="formData.Concernedoutlivingsavings" name="concerned_living_savings" id="concerned_living_savings" data-msg-required="Field Required" class="form-control" required="">
                                    <option value="" selected="" disabled="">Select an option</option>
                                    <option value="Very concerned">Very concerned</option>
                                    <option value="Somewhat concerned">Somewhat concerned</option>
                                    <option value="Not concerned">Not concerned</option>
                                </select>
                                <label v-if="concernedEmpty" class="error" for="email">Required Field</label>
                            </div>
                            <div class="form-input col-12">
                                <label for="inv_amt">How much money have you saved for retirement? *</label>
                                <select v-model="formData.Moneysavedforretirement" name="inv_amt" id="inv_amt" class="form-control" data-msg-required="Field Required" required="">
                                    <option value="" selected="" disabled="">Select an option</option>
                                    <option value="$100-$250K">$100,000 - $250,000</option>
                                    <option value="$250-$500K">$250,000 - $500,000</option>
                                    <option value="$500-$1M">$500,000 - $1,000,000</option>
                                    <option value="$1M-$2M">$1,000,000 - $2,000,000</option>
                                    <option value="$2M+">Over $2,000,000</option>
                                </select>
                                <label v-if="moneySavedEmpty" class="error" for="email">Required Field</label>
                            </div>
                            <div class="form-input col-12">
                                <label for="most_important_you">When preparing for your retirement finances, what's most important to you? *</label>
                                <textarea v-model="formData.Mostimportanttoyou" name="most_important_you" id="most_important_you" maxlength="500" rows="3" required></textarea>
                            </div>
                            <input type="hidden" v-model="formData.utm_source" name="utm_source">
                            <input type="hidden" v-model="formData.utm_medium" name="utm_medium">
                            <input type="hidden" v-model="formData.utm_campaign" name="utm_campaign">
                            <input type="hidden" v-model="formData.gclid" name="gclid">
                            <input type="hidden" v-model="formData.utm_term" name="utm_term">
                            <input type="hidden" v-model="formData.utm_content" name="utm_content">
                            <div class="form-input col-12">
                                <button type="submit" id="submitButton">Get Free Evaluation</button>
                                <p class="subtext">
                                    **No cost and no obligation beyond completing a short request form and you are not committed to buying anything. We respect your privacy.
                                </p>
                            </div>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
export default {
    name: 'FormSection',
    mounted() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'annuity_evaluation_quiz_completed'
        });
        console.log(this.$store.state.id);
    },
    data() {
        return {
            windowWidth: screen.width,
            formData: {
                first_name: "",
                last_name: "",
                phone: "",
                email: "",
                Concernedoutlivingsavings: "",
                Moneysavedforretirement: "",
                Mostimportanttoyou: "",
                utm_source: typeof this.$store.state.formData.utm_source === 'object' ? this.$store.state.formData.utm_source.value : this.$store.state.formData.utm_source,
                utm_medium: typeof this.$store.state.formData.utm_medium === 'object' ? this.$store.state.formData.utm_medium.value : this.$store.state.formData.utm_medium,
                utm_campaign: typeof this.$store.state.formData.utm_campaign === 'object' ? this.$store.state.formData.utm_campaign.value : this.$store.state.formData.utm_campaign,
                utm_term: typeof this.$store.state.formData.utm_term === 'object' ? this.$store.state.formData.utm_term.value : this.$store.state.formData.utm_term,
                utm_content: typeof this.$store.state.formData.utm_content === 'object' ? this.$store.state.formData.utm_content.value : this.$store.state.formData.utm_content,
                gclid: typeof this.$store.state.formData.gclid === 'object' ? this.$store.state.formData.gclid.value : this.$store.state.formData.gclid,
                Q1: typeof this.$store.state.answers.Q1 === 'object' ? this.$store.state.answers.Q1.value : this.$store.state.answers.Q1,
                Q2: typeof this.$store.state.answers.Q2 === 'object' ? this.$store.state.answers.Q2.value : this.$store.state.answers.Q2,
                Q3: typeof this.$store.state.answers.Q3 === 'object' ? this.$store.state.answers.Q3.value : this.$store.state.answers.Q3,
                Q4: typeof this.$store.state.answers.Q4 === 'object' ? this.$store.state.answers.Q4.value : this.$store.state.answers.Q4,
                Q5: typeof this.$store.state.answers.Q5 === 'object' ? this.$store.state.answers.Q5.value : this.$store.state.answers.Q5,
                Q6: typeof this.$store.state.answers.Q6 === 'object' ? this.$store.state.answers.Q6.value : this.$store.state.answers.Q6,
                tag: 'Annuity Evaluation Requested',
                source: 'Annuity Evaluation 3.0 E',
                AnnuityType: '',
                Challenge: null,
                ProfileSalesPage: '',
                tags_add: [
                    1439
                ],
                did: this.$store.state.id
            },
            emailInvalid: false,
            phoneInvalid: false,
            fnameEmpty: false,
            lnameEmpty: false,
            emailEmpty: false,
            phoneEmpty: false,
            concernedEmpty: false,
            moneySavedEmpty: false,
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth >= 770; // Adjust the breakpoint value as needed
        },
        annuity_type() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now') {
                type = 'Income Now'
            } else if(this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'Income Later'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Safe Growth'
            }
            return type;
        },
        annuity_type_form() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now') {
                type = 'Retirement Income'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Safe Growth'
            } else {
                type = 'Retirement Income'
            }
            return type;
        },
        annuity_type_text() {
            let gender_text = ''
            if(this.$store.state.answers.Q1.text === 'Woman') {
                gender_text = 'women'
            } else if(this.$store.state.answers.Q1.text === 'Man') {
                gender_text = 'men'
            }
            let marital_text = ''
            if(this.$store.state.answers.Q2.text === 'Married') {
                marital_text = 'married'
            } else if(this.$store.state.answers.Q2.text === 'Single') {
                marital_text = 'single'
            }
            let age_text = ''
            if(this.$store.state.answers.Q3.number === '50') {
                age_text = `under the age of ${this.$store.state.answers.Q3.number}`
            } else if(this.$store.state.answers.Q3.number === '70') {
                age_text = `over the age of ${this.$store.state.answers.Q3.number}`
            } else {
                age_text = `between the ages of ${this.$store.state.answers.Q3.number}`
            }
            let age = age_text
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now') {
                type = 'receive income now'
            } else if(this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'receive income later'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'safely grow their money'
            }
            //let retired = this.$store.state.answers.Q4.text
            let gender = gender_text
            let marital_status = marital_text
            let categories = type
            return `We help <b style="font-weight: 800;">${gender}</b> who are <b style="font-weight: 800;">${marital_status}</b> and <b style="font-weight: 800;">${age}</b> <b style="font-weight: 800;">${categories}</b> with annuities.`
        }
    },
    methods: {
        ...mapActions(['saveRepName', 'setLoading']), 
        goToForm() {
            document.getElementById('form-section').scrollIntoView({ behavior: 'smooth' })
        },
        formatInput() {
        let cleaned = this.formData.phone.replace(/\D/g, '');

        this.formData.phone = this.phoneFormat(cleaned);
        },
        phoneFormat(input) {
        let formatted = '';

        for (let i = 0; i < input.length; i++) {
            if (i === 0) {
            formatted += `(${input[i]}`;
            } else if (i === 3) {
            formatted += `) ${input[i]}`;
            } else if (i === 6) {
            formatted += `-${input[i]}`;
            } else {
            formatted += input[i];
            }
        }

        return formatted;
        },
        async submitForm() {
            const fieldValue = this.$store.state.answers.Q5.text;
            switch (fieldValue) {
              case 'I Want Income Now':
                    this.formData.tags_add.push(268);
                    this.formData.ProfileSalesPage = "https://quiz.annuitygator.com/in-results-ss";
                    this.formData.AnnuityType = "#1";
                break;
              case 'I Want Income Later':
                    this.formData.tags_add.push(270);
                    this.formData.ProfileSalesPage = "https://quiz.annuitygator.com/il-results-ss";
                    this.formData.AnnuityType = "#2";
                break;
              case 'I Want To Safely Grow My Money':
                    this.formData.tags_add.push(272);
                    this.formData.ProfileSalesPage = "https://quiz.annuitygator.com/sg-results-ss";
                    this.formData.AnnuityType = "#3";
                break;
            }
            this.setLoading(true);
            this.goToForm();
            const url = 'https://lds.advisorinternetmarketing.com/api/verify';
            const url2 = `https://lds.advisorinternetmarketing.com/api/nleads`;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer 6|X2qrDQC118IzJbjIBC47wTXYgLdl8ImtLPlTMRwYd5eb3a07',
            };
            try {
                const validate = {
                    email: this.formData.email,
                    phone: this.formData.phone,
                };
                const payload = {
                    ...this.formData
                };
                const response = await axios.post(url, validate, { headers });

                if (response.data.data.email === 'invalid') {
                    this.emailInvalid = true;
                    setTimeout(() => {
                        this.emailInvalid = false; // Reset the flag after 5 seconds
                    }, 8000);
                }
                if (response.data.data.phone === 'invalid') {
                    this.phoneInvalid = true;
                    setTimeout(() => {
                        this.phoneInvalid = false; // Reset the flag after 5 seconds
                    }, 8000);
                }

                if (this.formData.first_name === '') {
                    this.fnameEmpty = true;
                    setTimeout(() => {
                        this.fnameEmpty = false; // Reset the flag after 5 seconds
                    }, 8000);
                }
                if (this.formData.last_name === '') {
                    this.lnameEmpty = true;
                    setTimeout(() => {
                        this.lnameEmpty = false; // Reset the flag after 5 seconds
                    }, 8000);
                }
                if (this.formData.Concernedoutlivingsavings === '') {
                    this.concernedEmpty = true;
                    setTimeout(() => {
                        this.concernedEmpty = false; // Reset the flag after 5 seconds
                    }, 8000);
                }
                if (this.formData.Moneysavedforretirement === '') {
                    this.moneySavedEmpty = true;
                    setTimeout(() => {
                        this.moneySavedEmpty = false; // Reset the flag after 5 seconds
                    }, 8000);
                }

                if (
                    response.data.data.email === 'valid' &&
                    response.data.data.phone === 'valid'
                ) {
                    const res = await axios.post(url2, payload, { headers });
                    const id = res.data.id
                    const encodedId = btoa(id)
                    const did = this.$store.state.id
                    const utmSource = this.$store.state.formData.utm_source
                    // This event should trigger when the user completes the form that comes right after the quiz
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'annuity_evaluation_form_completed',
                        email: this.formData.email, // insert email of user here
                        phone: this.formData.phone // e.g. '+19292223343'
                    });
                    window.location.href = `/thank-annuity-evaluation/?i=${encodedId}&did=${did}&utm_source=${utmSource}`;
                }

                this.setLoading(false);
            } catch (error) {
                console.error('Error making POST request:', error);
                this.setLoading(false);
            }
        },

    },
}
</script>

<style lang="scss" scoped>
.bg-cover {
    background-image: url(../assets/img/bg/topn.webp);
    background-size: cover !important;
    background-position: bottom !important;
    background-repeat: no-repeat;
}
.error {
    font-size: 16px;
    font-weight: 700;
    color: #ff000070;
    padding-top: 20px;
    display: block;
    margin: 0 0 24px;
    line-height: 0;
    margin-bottom: 0;
}
#form {
    background-image: url(../assets/img/bg/topn.webp);
    background-size: auto;
    background-position: top;
    background-repeat: no-repeat;
    padding: 15px 0 40px;
    width: 100%;
    > .form-container {
        width: 100%;
        max-width: 1250px;
        padding: 10px;
        @media (min-width: $breakpoint-mobile) {
            padding: 0 20px 0 5px;
        }
        @media (min-width: $breakpoint-tablet) {
            padding: 0 25px;
            max-width: 1280px;
        }
        > .row {
            padding: 0 3%;
            @media (min-width: $breakpoint-mobile) {
                padding: 0 1%;
            }
            @media (min-width: $breakpoint-tablet) {
                padding: 0;
            }
            > .text-section {
                color: #fff;
                text-align: center;
                @media (min-width: $breakpoint-mobile) {
                    text-align: left;
                    padding-left: 1.5%;
                }
                @media (min-width: $breakpoint-tablet) {
                    padding-right: 0;
                }
                > .title {
                    font-size: 36px;
                    font-weight: 800;
                    margin-top: 40px;
                    line-height: 40px;
                    font-family: $font-fam;
                    @media (min-width: $breakpoint-mobile) {
                        margin-bottom: 1%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        padding-right: 25%;
                    }
                }
                > .affirmation {
                    font-size: 25px;
                    color: var(--white);
                    padding-bottom: 0.5rem;
                    padding-top: 1rem!important;
                    font-weight: normal;
                    font-family: $font-fam;
                    line-height: 40px;
                    @media (min-width: $breakpoint-mobile) {
                        margin-top: 0;
                        margin-bottom: 0;
                        
                    }
                    @media (min-width: $breakpoint-tablet) {
                        padding-right: 30%;
                    }
                }
                > .checks {
                    font-size: 20px;
                    font-weight: 400;
                    margin-bottom: 20px;
                    text-align: left;
                    padding-left: 0;
                    font-family: $font-family-2;
                    @media (min-width: $breakpoint-mobile) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        padding-right: 8%;
                    }
                    > li {
                        display: flex;
                        align-items: center;
                        margin-bottom: 0px;
                        padding-right: 0;
                        @media (min-width: $breakpoint-mobile) {
                            padding-right: 0;
                            width: 122%;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            padding-right: 10%;
                            width: 100%;
                        }
                        > .text-section {
                            height: max-content;
                            width: 100%;
                            @media (min-width: $breakpoint-mobile) {
                                width: 80%;
                                padding-right: 20%;
                                margin-top: 1.25rem;
                            }
                            > .row {
                                display: flex;
                                align-items: flex-start;
                                > .col-1 {
                                    > .check {
                                        width: 25px;
                                        margin-right: 10px;
                                        margin-top: 2px;
                                    }
                                }
                                > .col-11 {
                                    > p {
                                        line-height: 1.75rem;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                }
                > .complete-form {
                    display: flex;
                    align-items: center;
                    
                    flex-direction: column;
                    font-family: $font-fam;
                    @media (min-width: $breakpoint-mobile) {
                        margin-top: 40px;
                    }
                    > .complete {
                        font-size: 33px;
                        font-weight: 800;
                        margin-right: 20px;
                        line-height: 1;
                        color: var(--black);
                        margin-bottom: 0;
                        margin-top: 0;
                        @media (min-width: $breakpoint-mobile) {
                            margin-right: 0;
                        }
                    }
                    > .arrow {
                        width: 95%;
                        @media (min-width: $breakpoint-tablet) {
                            margin-top: 2%;
                        }
                    }
                }
            }
            > .form-section {
                padding: 0;
                > .form-cont {
                    padding: 0;
                    @media (min-width: $breakpoint-mobile) {
                        padding: 0 0 0 5%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        padding: 0 25px;
                    }
                    > h1 {
                        line-height: 1;
                        color: var(--black);
                        font-weight: 800;
                        font-size: 24px;
                        margin-top: 0;
                        font-family: $font-fam;
                }
                > form {
                    margin: auto;
                    box-shadow: -1px 1px 13px 0 rgba(0,0,0,.75);
                    -webkit-box-shadow: 0 1px 13px 0 rgba(0,0,0,.24);
                    -moz-box-shadow: -1px 1px 13px 0 rgba(0,0,0,.75);
                    border-radius: 10px;
                    padding:3% 3% 5%;
                    margin-top: 40px;
                    width: 90%;
                    background-color: #fff;
                    background-size: cover;
                    @media (min-width: $breakpoint-mobile) {
                        width: 86%;
                        padding: 3% 7% 5%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        width: 85%;
                        padding: 3% 0% 3%;
                    }
                    > p {
                        color: var(--black);
                        line-height: 1.5;
                        font-size: 22px;
                        font-weight: 400;
                        margin-bottom: 20px;
                        font-family: $font-fam;
                        @media (min-width: $breakpoint-mobile) {
                            font-size: 22px;
                            line-height: 35px;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            font-size: 22px;
                            line-height: 33px;
                        }
                        > .free {
                            font-weight: 700;
                            padding: 0;
                            @media (min-width: $breakpoint-mobile) {
                                font-weight: 700;
                            }
                            @media (min-width: $breakpoint-tablet) {
                                padding: 0 5%;
                            }
                        }
                    }
                    > .row {
                        padding: 0 5%;
                        @media (min-width: $breakpoint-mobile) {
                            padding: 0 2%;
                        }
                        @media (min-width: $breakpoint-tablet) {
                            padding: 0 6%;
                        }
                        > .form-input {
                            margin-bottom: 20px;
                            padding: 2% 6%;
                            @media (min-width: $breakpoint-mobile) {
                                padding: 0;
                            }
                            @media (min-width: $breakpoint-tablet) {
                                padding: 0 3%;
                            }
                            > label {
                                font-size: 18px;
                                font-weight: 600;
                                margin-bottom: 10px;
                                font-family: $font-family-2;
                                float: left;
                                text-align: left;
                                @media (min-width: $breakpoint-mobile) {
                                    padding-top: 8px;
                                }
                            }
                            > input {
                                width: -webkit-fill-available;
                                height: 30px;
                                padding: 0.375rem 0.75rem;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #212529;
                                background-color: #fff;
                                background-clip: padding-box;
                                border: 1px solid #ced4da;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                border-radius: 0.375rem;
                                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                &:focus {
                                    outline: none;
                                }
                                @media (min-width: $breakpoint-mobile) {
                                    height: 40px;
                                }
                                @media (min-width: $breakpoint-tablet) {
                                    height: 30px;
                                }
                            }
                            > select {
                                width: -webkit-fill-available;
                                height: 44px;
                                padding: 0.375rem 0.75rem;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #212529;
                                background-color: #fff;
                                background-clip: padding-box;
                                border: 1px solid #ced4da;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                border-radius: 0.375rem;
                                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                &:focus {
                                    outline: none;
                                }
                            }
                            > textarea {
                                font-family: Arial, Helvetica, sans-serif;
                                width: -webkit-fill-available;
                                padding: 0.375rem 0.75rem;
                                font-size: 1rem;
                                font-weight: 400;
                                line-height: 1.5;
                                color: #212529;
                                background-color: #fff;
                                background-clip: padding-box;
                                border: 1px solid #ced4da;
                                -webkit-appearance: none;
                                -moz-appearance: none;
                                appearance: none;
                                border-radius: 0.375rem;
                                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                                @media (min-width: $breakpoint-mobile) {
                                }
                            }
                            > button {
                                background: #f06543;
                                border: none;
                                border-radius: 9px;
                                box-sizing: border-box;
                                color: #fff;
                                cursor: pointer;
                                display: block;
                                font-size: 20px;
                                margin-top: 0;
                                margin-bottom: 20px;
                                padding: 15px 30px 20px;
                                text-decoration: none;
                                text-transform: uppercase;
                                width: 100%;
                                font-weight: 700;
                                margin-left: 7px;
                                font-family: $font-fam;
                                &:hover {
                                    background-color: #d45a3b;
                                }
                                @media (min-width: $breakpoint-mobile) {
                                    margin-bottom: 0;
                                }
                            }
                            > .subtext {
                                font-size: 12px;
                                color: #222;
                                margin-top: 2px;
                                padding: 0 2%;
                                line-height: 1.5;
                                @media (min-width: $breakpoint-mobile) {
                                    padding: 0 3%;
                                }
                            }
                        }
                    }
                }
                }
            }
        }
    }
}
</style>