<template>
    <section id="companies">
        <div class="text-section">
            <h1 v-if="annuity_type === 'Income'">Review The Top Paying Income Annuities From The Strongest Top-Rated Insurance Companies</h1>
            <h1 v-else>Review The Top Paying Protected Growth Annuities From Strongest Top-Rated Insurance Companies</h1>
        </div>
        <div class="img-section">
            <div class="row">
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160" height="69" imageName="allianz" altText="Allianz Logo" />
            </div>
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160px" height="69px" imageName="athene" altText="Athene Logo" />
            </div>
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160px" height="69px" imageName="ae" altText="American Equity Logo" />
            </div>
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160px" height="69px" imageName="nyl" altText="New York Life Logo" />
            </div>
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160px" height="69px" imageName="jackson" altText="Jackson Logo" />
            </div>
            <div class="img col-12 col-sm-6 col-md-4 col-lg-2">
                <ImageHolder width="160px" height="69px" imageName="lincoln" altText="Lincoln Logo" />
            </div>
            </div>
        </div>
    </section>
</template>
<script>
import ImageHolder from './comps/ImageHolder.vue';
export default {
    name: 'CompanyLogos',
    components: {
        ImageHolder,
    },
    computed: {
        annuity_type() {
            let type = '';
            if(this.$store.state.answers.Q5.text === 'I Want Income Now' || this.$store.state.answers.Q5.text === 'I Want Income Later') {
                type = 'Income'
            } else if(this.$store.state.answers.Q5.text === 'I Want To Safely Grow My Money') {
                type = 'Growth'
            }
            return type;
        },
    }
}
</script>
<style lang="scss" scoped>
#companies {
    padding: 25px 0 20px;
    @media (min-width: $breakpoint-mobile) {
        padding: 15% 0;
    }
    @media (min-width: $breakpoint-tablet) {
        padding: 6% 0;
    }
    > .text-section {
        padding: 0 5%;
        @media (min-width: $breakpoint-mobile) {
            padding: 0 4%;
        }
        @media (min-width: $breakpoint-tablet) {
            padding: 0 20%;
        }
        > h1 {
        text-align: center;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 50px;
        font-family: $font-family-4;
        color: var(--black);
        @media (min-width: $breakpoint-mobile) {
            font-size: 30px;
            padding: 0 4%;
            margin-bottom: 30px;
        }
        @media (min-width: $breakpoint-tablet) {
            padding: 0 10%;
        }
    }
    }
    > .img-section {
        padding: 0 10%;
        > .row {
            > .img {
                padding: 0;
                > img {
                    width: 50%;
                    @media (min-width: $breakpoint-mobile) {
                        width: 70%;
                    }
                    @media (min-width: $breakpoint-tablet) {
                        width: 80%;
                    }
                }
            }
        }
    }
}
</style>