<template>
    <section id="footer" :class="{'d-none' : isThanks}">
        <div class="container">
            <div class="footer">
                    <div class="row foot-container">
                    <div class="col-12">
                        <ul>
                            <li><a href="https://www.annuitygator.com/about">ABOUT</a></li>
                            <li><a href="https://www.annuitygator.com/contact">CONTACT US</a></li>
                            <li><a href="https://www.annuitygator.com/annuity-reviews/">ANNUITY REVIEWS</a></li>
                            <li><a href="https://www.annuitygator.com/privacy-policy/">TERMS & CONDITIONS</a></li>
                        </ul>
                    </div>
                    <hr class="divider">
                    <div class="col-12">
                        <div class="copy">
                            <p class="green-text">
                                This information will remain private and confidential. It will only be used by Annuity Gator to determine if an annuity is right for you. 
                            </p>
                            <p>
                            All content is for educational purposes only. Before purchasing any investment product be sure to do your own due diligence and consult a properly licensed professional should you have specific questions as they relate to your individual circumstances. Annuity product guarantees rely on the financial strength and claims-paying ability of the issuing insurer. Annuity riders may be available for an additional annual premium that can provide additional benefits and income guarantees. By contacting us you may speak with an insurance licensed agent in your state, and you may be offered insurance products for sale.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'FooterSection',
    data() {
        return {
            windowWidth: screen.width,
        };
    },
    computed: {
        isThanks() {
            if(this.$route.path === '/not-qualified' || this.$route.path === '/thank-annuity-evaluation-e') {
                return true;
            } else {
                return false;
            }
        },
    },
}
</script>

<style lang="scss" scoped>
#footer {
    display: block;
    width: 100%;
   > .container {
    display: flex;
    flex-direction: column;
    max-width: 1700px;
    padding: 15px 10px;
    @media (min-width: $breakpoint-mobile) {
        padding: 15px 5px;
    }
    > .footer {
        margin-top: 0;
        margin-bottom: 10px;
        width: 100%;
        color: #147b0a !important;
        line-height: 1.5;
        @media (min-width: $breakpoint-mobile) {
            
        }
        @media (min-width: $breakpoint-tablet) {
            margin-top: 1%;
        }
        > .foot-container {
            justify-content: center;
            @media (min-width: $breakpoint-mobile) {
                padding: 0;
            }
            @media (min-width: $breakpoint-tablet) {
                padding: 0 13%;
            }
            > .divider {
                width: 65%;
                margin: 0.2rem 0 0;
                opacity: 0.5;
                @media (min-width: $breakpoint-mobile) {
                    width: 70%;
                }
                @media (min-width: $breakpoint-tablet) {
                    width: 100%;
                }
            }
            > .col-12 {
            display: flex;
            align-items: center;
            justify-content: center;
            
            > ul {
                display: flex;
                align-items: center;
                justify-content: center;
                list-style: none;
                flex-wrap: wrap;
                padding-left: 0;
                font-family: $font-family-2;
                @media (min-width: $breakpoint-mobile) {
                    padding: 0;
                    flex-wrap: wrap;
                }
                > li {
                    margin: 0 0.5rem;
                    padding-left: 5px;
                    padding-right: 5px;
                    @media (min-width: $breakpoint-mobile) {
                        margin: 0 2rem;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                    > a {
                        color: #147b0a !important;
                        font-size: 13px;
                        font-weight: 700;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
            > .copy {
                width: 100%;
                > .green-text {
                    font-size: 12px;
                    text-align: center;
                    color: #147b0a  !important;
                    margin-bottom: 0;
                    margin-top: 1%;
                    font-family: $font-family-2;
                }
                > p {
                    color: #147b0a  !important;
                    margin-top: 0;
                    font-size: 12px;
                    text-align: center;
                    font-family: $font-fam;
                }
            }
            }
        }
    }
   }
}
</style>