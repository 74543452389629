<template>
    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
        <div
          :class="`wistia_embed wistia_async_${videoId} seo=false videoFoam=true`"
          style="height:100%;position:relative;width:100%"
        >
          <div
            class="wistia_swatch"
            style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
          >
            <img
              :src="swatchImageUrl"
              style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
              alt=""
              aria-hidden="true"
              @load="showSwatch = true"
            />
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showSwatch: false,
      };
    },
    computed: {
      swatchImageUrl() {
        return `https://fast.wistia.com/embed/medias/${this.videoId}/swatch`;
      },
    },
    props: {
      videoId: String,
    },
  };
  </script>
  
  <style scoped>
  /* Add your component-specific styles here */
  </style>
  